import React, { useState } from "react";
import { updateHistory } from "../../../utils/api";

// Component for questions on Patient Info Page
const ConditionQuestion = ({ condition, ariaId, medHistoryId }) => {
  let text = condition.text !== "0" ? condition.text : "";
  const [selectedOption, setSelectedOption] = useState(condition.value);
  const [dbValue, setDbValue] = useState({
    value: condition.value,
    text: text,
  });
  const [additionalInfo, setAdditionalInfo] = useState(text || "");
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);

    e.target.value !== dbValue.value.toString()
      ? setCanUpdate(true)
      : setCanUpdate(false);
  };

  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);

    e.target.value !== dbValue.text ? setCanUpdate(true) : setCanUpdate(false);
  };

  const addEntry = () => {
    const payload = {};

    if (selectedOption !== dbValue.value.toString()) {
      if (selectedOption === "false") {
        setAdditionalInfo("");
      }
      const column = `${condition.db}`;
      payload[column] = selectedOption;
    }

    if (additionalInfo !== dbValue.text) {
      const column = `${condition.db}_text`;
      payload[column] = additionalInfo;
    }

    updateHistory(medHistoryId, payload)
      .then((res) => res.json())
      .then((data) => {
        setCanUpdate(false);
        setErrMessage(false);
        setDbValue({
          value: selectedOption,
          text: additionalInfo,
        });
      })
      .catch((err) => {
        setErrMessage(true);
        console.log(err);
      });
  };

  return (
    <div className="condition-field">
      <label htmlFor={ariaId}>{condition.label}</label>
      <select value={selectedOption} onChange={handleSelectChange} id={ariaId}>
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </select>

      {selectedOption.toString() === "true" && (
        <input
          type="text"
          className="full-width-input"
          placeholder="Please specify"
          value={additionalInfo}
          onChange={handleInputChange}
        />
      )}

      {canUpdate && (
        <button className="med-history-add-btn" onClick={() => addEntry()}>
          Update
        </button>
      )}

      {errMessage && (
        <p className="err-message">
          Something has gone wrong with your request.
        </p>
      )}
    </div>
  );
};

const PatientInfoQuestions = ({ medHistory }) => {
  console.log(medHistory);
  if (medHistory === undefined) {
    return <div>Loading...</div>;
  }

  const medHistoryId = medHistory.id;

  // Patient Info Questions
  const patientInfo = [
    {
      id: 1,
      label: "Neurological Problems",
      value: medHistory.neuro || false,
      text: medHistory.neuro_text || "",
      db: "neuro",
    },
    {
      id: 2,
      label: "Diabetes",
      value: medHistory.diabetes || false,
      text: medHistory.diabetes_text || "",
      db: "diabetes",
    },
    {
      id: 3,
      label: "Hypertension",
      value: medHistory.hypertension || false,
      text: medHistory.hypertension_text || "",
      db: "hypertension",
    },
    {
      id: 4,
      label: "Bleeding Problems",
      value: medHistory.bleeding || false,
      text: medHistory.bleeding_text || "",
      db: "bleeding",
    },
    {
      id: 5,
      label: "Hepatitis",
      value: medHistory.hepatitis || false,
      text: medHistory.hepatitis_text || "",
      db: "hepatitis",
    },
    {
      id: 6,
      label: "Asthma",
      value: medHistory.asthma || false,
      text: medHistory.asthma_text || "",
      db: "asthma",
    },
    {
      id: 7,
      label: "Fainting/Dizziness",
      value: medHistory.fainting || false,
      text: medHistory.fainting_text || "",
      db: "fainting",
    },
    {
      id: 8,
      label: "Smoking",
      value: medHistory.smoking || false,
      text: medHistory.smoking_text || "",
      db: "smoking",
    },
    {
      id: 9,
      label: "Heart Disease",
      value: medHistory.heart_disease || false,
      text: medHistory.heart_disease_text || "",
      db: "heart_disease",
    },
  ];

  return (
    <>
      {patientInfo.map((condition, index) => (
        <ConditionQuestion
          ariaId={`patient-info-condition-${index}`}
          key={condition.id}
          condition={condition}
          medHistoryId={medHistoryId}
        />
      ))}
    </>
  );
};

export default PatientInfoQuestions;
