import React, { useState, useEffect } from "react";
import "./patientsearch.css";
import { Icon } from "@iconify/react";
import profileImg from "../../../assets/images/guest.webp";
import BackgroundImage from "../../../components/BackgroundImage";
import { Link } from "react-router-dom";
import {
  getRecentPatients,
  getRecentSurgeries,
  searchPatients,
  getUsersbyDOS,
  getPatientsbyDOB,
} from "../../../utils/api";

const PatientSearch = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("Name");
  const [userInput, setUserInput] = useState("");
  //  user submits a search
  const handleSearch = (e) => {
    e.preventDefault();

    if (userInput.trim() === "") {
      setUsers([]);
      return;
    }
    setLoading(true);
    setUsers([]);

    console.log(searchType);

    //  if search type is by (DOS)
    if (searchType === "DOS") {
      // Fetch users by DOS
      getUsersbyDOS(userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          setUsers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching patients:", err);
          setLoading(false);
        });
    } else if (searchType === "DOB") {
      // Fetch users by DOS
      getPatientsbyDOB(userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          setUsers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching patients:", err);
          setLoading(false);
        });
    } else {
      // Fetch users by other search types
      searchPatients(searchType, userInput.trim())
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.message) {
            if (
              data.message ===
              "No patients found matching the provided criteria."
            ) {
              console.log("here");
              setUsers([]);
              setLoading(false);
            }
          }
          setUsers(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Fetch recent patients
    getRecentPatients()
      .then((res) => res.json())
      .then(async (data) => {
        // Fetch recent surgeries
        const usersWithSurgeries = await Promise.all(
          data.map(async (user) => {
            const surgeriesRes = await getRecentSurgeries(user.id);
            const surgeriesData = await surgeriesRes.json();
            // Filter surgeries to match the current user
            const filteredSurgeries = surgeriesData.filter(
              (surgery) => surgery.patient_id === user.id
            );
            return { ...user, surgeries: filteredSurgeries };
          })
        );
        setUsers(usersWithSurgeries);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column">
        <BackgroundImage />
        <h1>Patient Search</h1>
        <div className="patient-search-input-group">
          <label htmlFor="select"></label>
          <select
            id="select"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="Name">Name</option>
            <option value="DOB">DOB</option>
            <option value="MRN">MRN</option>
            <option value="DOS">DOS</option>
          </select>
          <label htmlFor="patient-search"></label>
          <input
            id="patient-search"
            type="text"
            className="patient-search-input"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
          <button
            className="patient-search-btn"
            onClick={(e) => handleSearch(e)}
          >
            {isLoading ? "Searching..." : "Search"}
            <Icon icon="ic:round-search" className="patient-search-icon" />
          </button>
        </div>
        <div className="patient-search-card-container">
          {users.length > 0
            ? users.map((user) => <UserCard user={user} key={user.id} />)
            : !isLoading && <p>No Patients Found</p>}
        </div>
      </div>
    </div>
  );
};
const UserCard = ({ user }) => {
  const displayPhoto = (image) => {
    if (!image) {
      return profileImg;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };
  const latestSurgery = user.surgeries?.[0];
  return (
    <Link to={`/patient/${user.id}`}>
      <div key={user.id} className="patient-search-card">
        <div className="search-profile-image">
          <img src={displayPhoto(user.image)} alt="user profile" />
          <div>
            <h2 className="patient-search-name">
              {user.first_name} {user.last_name}
            </h2>
            <div className="mrn">
              <p>
                <span className="mrn-dob">MRN: </span>
                <span className="mrn-value">{user.MRN}</span>
              </p>
              <p>
                <span className="mrn-dob">DOB: </span>
                <span className="mrn-value">{user.DOB}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="patient-search-divider"></div>
        <div className="patient-search-proc-dos">
          <div className="procedure">
            Procedure: {latestSurgery ? latestSurgery.type : "N/A"}
          </div>
          <div className="dos">
            DOS: {latestSurgery ? latestSurgery.DOS : "N/A"} &nbsp;
            {latestSurgery ? latestSurgery.TOS : ""}
          </div>
          {/* Display when Documents are missing signatures */}
          <button className="missing-sig-btn hidden">Missing Signatures</button>
        </div>
      </div>
    </Link>
  );
};
export default PatientSearch;
