import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateSurgery, deleteSurgery } from "../../../utils/api";

const Surgery = ({ surgery, surgeries, setSurgeries }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbSurg, setDbSurg] = useState({
    surgery_name: surgery.surgery_name,
  });

  const [surg, setSurg] = useState({
    surgery_name: surgery.surgery_name,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setSurg((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (surg.surgery_name !== dbSurg.surgery_name) {
      const column = `surgery_name`;
      payload[column] = surg.surgery_name;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateSurgery(surgery.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbSurg(surg);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    console.log(surgeries);

    const updated = [];

    surgeries.map((item) => {
      if (item.id !== surgery.id) {
        updated.push(item);
      }
    });

    deleteSurgery(surgery.id)
      .then((res) => res.json())
      .then((data) => {
        setSurgeries(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(surg, dbSurg) ? setCanUpdate(false) : setCanUpdate(true);
  }, [surg]);

  return (
    <>
      {isEditing === false ? (
        <tr>
          <td className="surg-col-width">{dbSurg.surgery_name}</td>
          <td className="edit-trash-container w-100">
            <button
              className="medication-edit-btn"
              id="bj-edit-btn"
              aria-label="edit"
            >
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              className="medication-delete-btn"
              id="bj-delete-btn"
              aria-label="delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <Icon icon="bi:trash3" />
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td className="surg-col-width">
            <input
              type="text"
              placeholder="Enter surgery"
              value={surg.surgery_name}
              onChange={(e) => handleInputChange(e, "surgery_name")}
            />
          </td>
          <td className="flex-space-between w-100">
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              <Icon icon="material-symbols:close" />
            </button>
          </td>
        </tr>
      )}
    </>
  );
};

export default Surgery;
