import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AdminCreate.css";
import { createUser } from "../../../utils/api";
import BackgroundImage from "../../../components/BackgroundImage";

const AdminCreate = () => {
  // Get the current location object
  const location = useLocation();
  // State to manage the menu's open/close status
  const [menuOpen, setMenuOpen] = useState(false);
  // Function to toggle the menu open or closed
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to get the active tab name based on the current URL path
  const getActiveTabName = () => {
    switch (location.pathname) {
      case "/admin-user-search":
        return "Search for Users";
      case "/admin-approval":
        return "Pending Approval";
      case "/admin-create":
        return "Create New User";
      default:
        return "";
    }
  };

  /** Track form fields */
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [passConfirm, setPassConfirm] = useState("");
  const [form, setForm] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    role: "Doctor",
  });

  /** Handle form submit/create new user */
  const handlesubmit = (e) => {
    e.preventDefault();
    // console.log(form);

    if (passConfirm.trim() === "" || showError === true) {
      setShowError(true);
    } else {
      // Submit form AFTER password is validated
      createUser(form)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          setMessage("User added succesfully!");
          setForm({
            email: "",
            first_name: "",
            last_name: "",
            password: "",
            role: "Doctor",
          });
          setPassConfirm("");
          setShowMessage(true);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something has gone wrong, please try again later");
          setShowMessage(true);
        });
    }
  };

  /** Validate password and password confirmation inputs match before allowing submit */
  const checkMatch = (userInput) => {
    if (userInput !== null && userInput === form.password) {
      // Allow user to save IF they confirm password
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {/* Main container with a grid layout */}
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />

          <div className="second-column-item-heading">
            {/* Heading */}
            <h2 className="search-heading">Manage Users</h2>
          </div>
          <div className="second-column-item-links">
            <div className="menu-header">
              {/* Hamburger menu for toggling */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* Display the active tab name */}
              <div className="active-tab-name">{getActiveTabName()}</div>
            </div>
            {/* Menu items that toggle open/close */}
            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link
                to="/admin-user-search"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-user-search" ? "active" : ""
                }`}
              >
                Search for Users
              </Link>
              <Link
                to="/admin-approval"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-approval" ? "active" : ""
                }`}
              >
                Pending Approval
              </Link>
              <Link
                to="/admin-create"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-create" ? "active" : ""
                }`}
              >
                Create New User
              </Link>
            </div>
          </div>
          {/* New User Form */}
          <div className="grid-position">
            <form className="create-grid-box" onSubmit={(e) => handlesubmit(e)}>
              <h2 className="create-heading">Add New User</h2>
              <div className="create-grid-container">
                <div className="create-grid-item-label">Email</div>
                <div className="create-grid-item">
                  <input
                    className="create-input"
                    placeholder="Email"
                    required
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </div>
                <div className="create-grid-item-label">First Name</div>
                <div className="create-grid-item">
                  <input
                    className="create-input"
                    placeholder="First Name"
                    required
                    value={form.first_name}
                    onChange={(e) =>
                      setForm({ ...form, first_name: e.target.value })
                    }
                  />
                </div>
                <div className="create-grid-item-label">Last Name</div>
                <div className="create-grid-item">
                  <input
                    className="create-input"
                    placeholder="Last Name"
                    required
                    value={form.last_name}
                    onChange={(e) =>
                      setForm({ ...form, last_name: e.target.value })
                    }
                  />
                </div>
                <div className="create-grid-item-label">Password</div>
                <div className="create-grid-item">
                  <input
                    className="create-input"
                    placeholder="Password"
                    required
                    value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                  />
                </div>
                <div className="create-grid-item-label"></div>
                <div className="create-grid-item">
                  <input
                    className="create-input"
                    placeholder="Confirm Password"
                    value={passConfirm}
                    onChange={(e) => {
                      setPassConfirm(e.target.value);
                      checkMatch(e.target.value);
                    }}
                  />
                  {showError ? (
                    <p className="password-error">
                      Passwords do not match, please confirm password.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="create-grid-item-label">Role</div>
                <div className="create-grid-item">
                  <select
                    aria-label="role"
                    className="create-input"
                    required
                    value={form.role}
                    onChange={(e) => setForm({ ...form, role: e.target.value })}
                  >
                    <option value={"Doctor"}>Doctor</option>
                    <option value={"Anesthesiologist"}>Anesthesiologist</option>
                    <option value={"RN"}>RN (Registered Nurse)</option>
                    <option value={"LVN"}>
                      LVN (Licensed Vocational Nurse)
                    </option>
                    <option value={"Tech"}>Tech</option>
                    <option value={"Admin"}>Admin</option>
                    <option value={"Ancillary"}>Ancillary</option>
                  </select>
                </div>
                <div className="create-grid-item-label"></div>
                <div className="create-grid-item">
                  <button className="create-user-button">Create User</button>
                  {showMessage ? <p>{message}</p> : <></>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreate;
