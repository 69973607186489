import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateMedAllergy, deleteMedAllergy } from "../../../utils/api";

const MedicationAllergies = ({ allergy, medAllergies, setMedAllergies }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbmed, setDbMed] = useState({
    medication: allergy.medication,
    reaction: allergy.reaction,
  });

  const [medication, setMedication] = useState({
    medication: allergy.medication,
    reaction: allergy.reaction,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setMedication((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (medication.medication !== dbmed.medication) {
      const column = `medication`;
      payload[column] = medication.medication;
    }
    if (medication.reaction !== dbmed.reaction) {
      const column = `reaction`;
      payload[column] = medication.reaction;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateMedAllergy(allergy.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbMed(medication);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    console.log(allergy.id);

    const updated = [];

    medAllergies.map((medication) => {
      if (medication.id !== allergy.id) {
        updated.push(medication);
      }
    });

    deleteMedAllergy(allergy.id)
      .then((res) => res.json())
      .then((data) => {
        setMedAllergies(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(medication, dbmed) ? setCanUpdate(false) : setCanUpdate(true);
  }, [medication]);

  return (
    <>
      {isEditing === false ? (
        <div className="mobile-body">
          <div>
            <p>{medication.medication}</p>
            <p>{medication.reaction}</p>
          </div>
          <div className="mobile-icons">
            <button className="medication-edit-btn" aria-label="Edit">
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              style={{ marginLeft: "30px" }}
              className="medication-delete-btn"
              aria-label="Delete"
            >
              <Icon
                icon="bi:trash3"
                onClick={() => {
                  handleDelete();
                }}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="mobile-inputs">
          <label>Medication:</label>
          <input
            type="text"
            name="medication"
            value={medication.medication}
            onChange={(e) => handleInputChange(e, "medication")}
          />
          <label>Reaction:</label>
          <input
            type="text"
            name="reaction"
            value={medication.reaction}
            onChange={(e) => handleInputChange(e, "reaction")}
          />

          <div className="flex-space-between" style={{ alignItems: "center" }}>
            <button
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                marginLeft: "0",
              }}
              aria-label="Add"
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              Close <Icon icon="material-symbols:close" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MedicationAllergies;
