import React from "react";
import "./nursingQuestionnaire.css";

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active || false, 
    };
  }

  handleChange = () => {
    const { onToggle } = this.props;
    this.setState((prevState) => {
      const newActiveState = !prevState.active;
      // Call the parent's onToggle function to handle the conversion
      onToggle(newActiveState);
      return { active: newActiveState };
    });
  };

  render() {
    return (
      <label className="checkbox-label" htmlFor="slider-checkbox">
        <input
          className="slider-checkbox hidden-checkbox"
          type="checkbox"
          id="slider-checkbox"
          checked={this.state.active} 
          onChange={this.handleChange}
        />
        <div className={`slider-toggle ${this.state.active ? 'active' : ''}`} />
        <span className="toggle-label">
          Switch to {this.state.active ? "Standard" : "Metric"}
        </span>
      </label>
    );
  }
}

export default ToggleSwitch;