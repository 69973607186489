import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import {
  getPatientById,
  getSurgerybyId,
  getPatientInformation,
} from "../../../utils/api";
import { formatTime } from "../../../utils/helpers";
import "./surgeryview.css";

const SurgeryView = () => {
  // State for patient info and surgery
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState([]);
  const [allergies, setAllergies] = useState([
    "Penecillin",
    "Peanuts",
    "Tylenol",
  ]);
  const [questionnaire_id, seQuestionnaireId] = useState("");
  const [loading, setLoading] = useState(true);

  const { id, surgery_id } = useParams();

  // useEffect to fetch patient data by id
  useEffect(() => {
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setSelectedPatient(data);

        // Get the Patient Information
        getPatientInformation(data.patientInformation.id)
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            const dbAllergies = [];
            data.foodAllergies.map((food) => dbAllergies.push(food.food));
            data.medAllergies.map((med) => dbAllergies.push(med.medication));
            setAllergies(dbAllergies);

            // Get the Surgery
            getSurgerybyId(surgery_id)
              .then((res) => res.json())
              .then((data) => {
                // console.log(data);

                if (data.nursingQuestionaire) {
                  seQuestionnaireId(data.nursingQuestionaire.id);
                }

                setSelectedSurgery(data);

                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Menu />
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column surgery-card">
          <div
            className="col-5-lg col-2-sm"
            style={{ marginBottom: "40px", paddingLeft: "40px" }}
          >
            <div>
              <p style={{ marginBottom: "7px" }}>Physician</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.physician || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Anesthesiologist</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.anesthesiologist || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Operating Room</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.operating_room || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Date of Service</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.DOS || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Time of Service</p>
              <p style={{ marginTop: "0px" }}>
                <b>
                  {selectedSurgery.TOS
                    ? formatTime(selectedSurgery.TOS)
                    : "N/A"}
                </b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Procedure</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.type || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Sex</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedPatient.gender || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Emergency Contact</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.emergency_contact || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Patient Contact</p>
              <p style={{ marginTop: "0px" }}>
                <b>{selectedSurgery.patient_contact || "N/A"}</b>
              </p>
            </div>

            <div>
              <p style={{ marginBottom: "7px" }}>Allergies</p>
              <p style={{ marginTop: "0px", color: "#950000" }}>
                <b>{allergies.join(", ") || "N/A"}</b>
              </p>
            </div>
          </div>

          <div className="col-4-lg col-2-md">
            <button className="hover-pointer btn-grey">
              Ready For Surgery?
            </button>
            <button className="hover-pointer btn-grey">
              Patient Signature View
            </button>

            <button className="btn-primary hover-pointer">
              <a
                href={`/patient/${id}/nursing-questionnaire/${questionnaire_id}`}
              >
                {" "}
                Nursing Questionaire
              </a>
            </button>

            <button className="hover-pointer btn-grey">Send to Tebra</button>
          </div>

          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </>
  );
};

export default SurgeryView;
