import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateMedications, deleteMedications } from "../../../utils/api";

const CurrentMedications = ({ med, medications, setMedications }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbmed, setDbMed] = useState({
    name: med.name,
    dose: med.dose,
    route: med.route,
    frequency: med.frequency,
    status: med.status,
  });

  const [medication, setMedication] = useState({
    name: med.name,
    dose: med.dose,
    route: med.route,
    frequency: med.frequency,
    status: med.status,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setMedication((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (medication.name !== dbmed.name) {
      const column = `name`;
      payload[column] = medication.name;
    }
    if (medication.dose !== dbmed.dose) {
      const column = `dose`;
      payload[column] = medication.dose;
    }
    if (medication.route !== dbmed.route) {
      const column = `route`;
      payload[column] = medication.route;
    }
    if (medication.frequency !== dbmed.frequency) {
      const column = `frequency`;
      payload[column] = medication.frequency;
    }
    if (medication.status !== dbmed.status) {
      const column = `status`;
      payload[column] = medication.status;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateMedications(med.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbMed(medication);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    const updated = [];

    medications.map((medication) => {
      if (medication.id !== med.id) {
        updated.push(medication);
      }
    });

    deleteMedications(med.id)
      .then((res) => res.json())
      .then((data) => {
        setMedications(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(medication, dbmed) ? setCanUpdate(false) : setCanUpdate(true);
  }, [medication]);

  return (
    <>
      {isEditing === false ? (
        <div className="mobile-body">
          <div>
            <p>{medication.name}</p>
            <p>{medication.dose}</p>
            <p>{medication.route}</p>
            <p>{medication.frequency}</p>
            <p className={`status-badge ${medication.status.toLowerCase()}`}>
              {medication.status}
            </p>
          </div>
          <div className="mobile-icons">
            <button className="medication-edit-btn" aria-label="Edit">
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              className="medication-delete-btn"
              aria-label="Delete"
              style={{ marginLeft: "40px" }}
            >
              <Icon
                icon="bi:trash3"
                onClick={() => {
                  handleDelete();
                }}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="mobile-inputs">
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            type="text"
            name="name"
            value={medication.name}
            onChange={(e) => handleInputChange(e, "name")}
          />
          <label htmlFor="dose">Dose:</label>
          <input
            id="dose"
            type="text"
            name="dose"
            value={medication.dose}
            onChange={(e) => handleInputChange(e, "dose")}
          />
          <label htmlFor="route">Route:</label>
          <input
            id="route"
            type="text"
            name="route"
            value={medication.route}
            onChange={(e) => handleInputChange(e, "route")}
          />
          <label htmlFor="frequency">Frequency:</label>
          <input
            id="frequency"
            type="text"
            name="frequency"
            value={medication.frequency}
            onChange={(e) => handleInputChange(e, "frequency")}
          />
          <label htmlFor="status">Status</label>
          <select
            id="status"
            name="status"
            value={medication.status}
            onChange={(e) => handleInputChange(e, "status")}
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>

          <div className="flex-space-between" style={{ alignItems: "center" }}>
            <button
              aria-label="Add"
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                marginLeft: "0",
              }}
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              Close <Icon icon="material-symbols:close" />
            </button>
          </div>

          {errMessage ? (
            <p className="err-message">
              Something has gone wrong with your request.
            </p>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default CurrentMedications;
