import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";
import navLogo from "../../assets/images/navbar_logo.webp";
import footerLogo from "../../assets/images/sidebar_logo.webp";
import Auth from "../../utils/auth";
import { userRole as fetchUserRole, getUserProfile } from "../../utils/api";
import { DrSideNav, AdminSideNav, DefaultSideNav, SideNav } from "./Navigation";
import { Icon } from "@iconify/react";
import placeholder from "../../assets/images/guest.webp";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // State to manage whether the side navigation is open or closed
  const [isOpen, setIsOpen] = useState(false);
  // State to manage if the view is on a mobile device
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to toggle the side navigation menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Function to close the side navigation menu
  const closeMenu = () => {
    setIsOpen(false);
  };
  // Function to handle window resize events
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    if (window.innerWidth > 768) {
      setIsOpen(false); // Close the side navigation if screen is resized to a larger width
    }
  };
  // Fetch user role and manage side navigation state
  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      const user = Auth.getUser();

      if (user && user.id) {
        setIsLoggedIn(Auth.loggedIn());

        try {
          // Fetch user role
          const responseRole = await fetchUserRole(user.id);
          if (!responseRole.ok) {
            throw new Error("Failed to fetch user role");
          }
          const roleData = await responseRole.json();
          if (roleData && roleData.role) {
            setUserRole(roleData.role);
          } else {
            setUserRole("Default");
          }

          // Fetch user profile data
          const responseProfile = await getUserProfile(user.id);
          if (!responseProfile.ok) {
            throw new Error("Failed to fetch user profile");
          }
          const profileData = await responseProfile.json();
          // Store user data in state
          setUser(profileData);
        } catch (error) {
          setUserRole("Default");
        }
      } else {
        setUserRole("Default");
      }
      setIsLoading(false);
    };

    getUserData();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Sidenav hidden on login and signup
  const showSideNav = () => {
    return location.pathname !== "/" && location.pathname !== "/signup";
  };
  // Function to get  side navigation  based on user role
  const getSideNav = (role) => {
    if (!role) return DefaultSideNav;
    switch (role) {
      case "Admin":
        return AdminSideNav;
      case "Doctor":
        return DrSideNav;
      case "RN":
      case "LVN":
      case "Tech":
      case "Anesthesiologist":
        return SideNav;
      default:
        return DefaultSideNav;
    }
  };

  // Function to render the side navigation component
  const renderSideNav = () => {
    const SideNavComponent = getSideNav(userRole);
    return <SideNavComponent mobile={isMobile} closeMenu={closeMenu} />;
  };
  //render logout btn
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const displayPhoto = (image) => {
    if (!image) {
      return placeholder;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };

  // Only center logo on login/signup
  const isLoginOrSignup =
    location.pathname === "/" || location.pathname === "/signup";

  return (
    <div
      className={`nav-container ${isLoginOrSignup ? "login-signup-nav" : ""}`}
    >
      <nav className="nav">
        {isLoggedIn &&
          isMobile &&
          location.pathname !== "/" &&
          location.pathname !== "/signup" && (
            // Hamburger menu for mobile view
            <div className="hamburger-menu" onClick={toggleMenu}>
              <div className="hamburger-div"></div>
              <div className="hamburger-div"></div>
              <div className="hamburger-div"></div>
            </div>
          )}
        {/* Navigation bar logo */}
        <Link to="/dashboard">
          <img src={navLogo} alt="cornerstone" className="nav-logo" />{" "}
        </Link>
        {isLoggedIn && (
          <div className="profile-info">
            {/* User Name */}
            <div className="name-container" onClick={toggleDropdown}>
              <p className="profile-text">
                {user?.first_name} {user?.last_name}
              </p>
              <Icon
                icon="ph:caret-down-light"
                className={dropdownOpen === true ? "caret flip" : "caret"}
              />
            </div>
            {/* Profile Image */}
            <img
              src={displayPhoto(user?.image)}
              alt="profile"
              className="profile-img pointer"
              onClick={() => navigate("/profile")}
            />
            {/* Dropdown Menu */}
            {dropdownOpen && (
              <div className="dropdown-menu">
                <button className="logout-btn" onClick={() => Auth.logout()}>
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </nav>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        showSideNav() &&
        isLoggedIn && (
          <>
            {isMobile ? (
              <>
                <div
                  className={`overlay ${isOpen ? "open" : ""}`}
                  onClick={closeMenu}
                ></div>
                <div className={`sidenav ${isOpen ? "open" : ""}`}>
                  <div className="close-menu" onClick={closeMenu}>
                    <div style={{ cursor: "pointer" }}>X</div>
                  </div>
                  {renderSideNav()}
                  <div className="sidenav-footer">
                    <footer>
                      <img src={footerLogo} alt="cornerstone" />
                    </footer>
                  </div>
                </div>
              </>
            ) : (
              renderSideNav() // Render side nav for desktop view
            )}
          </>
        )
      )}
    </div>
  );
};

export default Nav;
