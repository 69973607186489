import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateInsurance, deleteBilling } from "../../../utils/api";

const Billing = ({ isAdmin, billings, setBillings, postBilling }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [count, setCount] = useState(billings || []);

  const [billingType, setBillingType] = useState("Insurance");
  const [billingInfo, setBillingInfo] = useState({
    provider: "",
    policyNumber: "",
    nameOnInsurance: "",
  });

  const getButton = () => {
    if (isEditing) {
      return (
        <button
          className="edit-section-btn"
          id="bj-edit-btn"
          aria-label="edit"
          onClick={() => setIsEditing(false)}
        >
          Close
          <Icon icon="material-symbols:close" />
        </button>
      );
    } else {
      return (
        <button
          className="edit-section-btn"
          id="bj-edit-btn"
          aria-label="edit"
          onClick={() => setIsEditing(true)}
        >
          Edit Section
          <Icon icon="typcn:edit" />
        </button>
      );
    }
  };

  const getBillingTable = (bill) => {
    switch (bill.type) {
      case "Insurance":
        return (
          <Insurance
            isAdmin={isAdmin}
            setBillingInfo={setBillingInfo}
            billingInfo={billingInfo}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
            bill={bill.insurances[0]}
          />
        );
      default:
        // code block
        return;
    }
  };

  const getNewBillingTable = () => {
    switch (billingType) {
      case "Insurance":
        return (
          <NewInsurance
            isAdmin={isAdmin}
            setBillingInfo={setBillingInfo}
            billingInfo={billingInfo}
            isEditing={isEditing}
            billings={billings}
            setBillings={setBillings}
            postBilling={postBilling}
            bill={{
              provider: "",
              group_policy_number: "",
              name_on_insurance: "",
            }}
          />
        );
      default:
        // code block
        return;
    }
  };

  useEffect(() => {
    setCount(billings);
    setOpenTable(false);
  }, [billings]);

  return (
    <>
      <div
        className="billing-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3 className="mobile-heading" htmlFor="billing">
          Billing
        </h3>
        {isAdmin ? <div className="edit-section">{getButton()}</div> : <></>}
      </div>

      {billings.length === 0 && isEditing ? (
        <>
          <select
            id="billing"
            value={billingType}
            onChange={(e) => setBillingType(e.target.value)}
          >
            <option value="Insurance">Insurance</option>
            <option value="Oop">Out of Pocket</option>
            <option value="Pip">Personal Injury</option>
            <option value="Comp">Worker's Comp</option>
          </select>
          <>{getNewBillingTable()}</>
        </>
      ) : (
        <></>
      )}

      {count.length === 0 && isEditing === false ? (
        <p>No Billing Information Available</p>
      ) : (
        <></>
      )}

      {billings.map((bill, index) => (
        <div key={bill.id}>{getBillingTable(bill)}</div>
      ))}

      {/* Add second Payer Button */}
      {isEditing &&
      count.length > 0 &&
      count.length < 2 &&
      openTable === false ? (
        <div className="payer-btn-container">
          <button
            className="payer-btn"
            onClick={() => {
              setOpenTable(true);
            }}
          >
            Add Second Payer
          </button>
        </div>
      ) : (
        <></>
      )}

      {openTable && isEditing && count.length < 2 ? (
        <>
          <select
            style={{ marginTop: "30px", width: "100%", marginBottom: "30px" }}
            // id="billing"
            value={billingType}
            onChange={(e) => setBillingType(e.target.value)}
          >
            <option value="Insurance">Insurance</option>
            <option value="Oop">Out of Pocket</option>
            <option value="Pip">Personal Injury</option>
            <option value="Comp">Worker's Comp</option>
          </select>
          <>{getNewBillingTable()}</>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Insurance = ({ isAdmin, isEditing, billings, setBillings, bill }) => {
  const [editBill, setEditBill] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [dbBilling, setDbBilling] = useState({
    provider: bill.provider,
    group_policy_number: bill.group_policy_number,
    name_on_insurance: bill.name_on_insurance,
  });
  const [newBilling, setNewBilling] = useState({
    provider: bill.provider,
    group_policy_number: bill.group_policy_number,
    name_on_insurance: bill.name_on_insurance,
  });

  const updateBilling = () => {
    const payload = {};

    if (newBilling.provider !== bill.provider) {
      const column = `provider`;
      payload[column] = newBilling.provider;
    }
    if (newBilling.group_policy_number !== bill.group_policy_number) {
      const column = `group_policy_number`;
      payload[column] = newBilling.group_policy_number;
    }
    if (newBilling.name_on_insurance !== bill.name_on_insurance) {
      const column = `name_on_insurance`;
      payload[column] = newBilling.name_on_insurance;
    }

    if (Object.keys(payload).length !== 0) {
      console.log(payload);

      udpateInsurance(bill.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbBilling(newBilling);
          setEditBill(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    const updated = [];

    billings.map((item) => {
      if (item.id !== bill.id) {
        updated.push(item);
      }
    });

    deleteBilling(bill.billing_id)
      .then((res) => res.json())
      .then((data) => {
        setBillings(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(newBilling, dbBilling) ? setCanUpdate(false) : setCanUpdate(true);
  }, [newBilling]);

  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Insurance */}
      <div className="billing-header">
        <h3 className="table-label">Insurance</h3>
        {isAdmin && isEditing && (
          <div style={{ marginLeft: "40px" }}>
            <button
              className="medication-edit-btn"
              id="bj-edit-btn"
              aria-label="edit"
            >
              <Icon icon="typcn:edit" onClick={() => setEditBill(true)} />
            </button>
            <button
              style={{ marginLeft: "40px" }}
              className="medication-delete-btn"
              id="bj-delete-btn"
              aria-label="delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <Icon icon="bi:trash3" />
            </button>
          </div>
        )}
      </div>
      <div className="mobile-inputs">
        {isAdmin && editBill && isEditing ? (
          <>
            <label htmlFor="provider">Provider:</label>
            <input
              type="text"
              name="provider"
              className={isEditing ? "" : "read-only"}
              value={newBilling.provider}
              onChange={(e) =>
                setNewBilling({
                  ...newBilling,
                  provider: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p style={{ margin: 0 }}>{dbBilling.provider}</p>
        )}

        {isAdmin && editBill && isEditing ? (
          <>
            {" "}
            <label htmlFor="policy-number">Policy Number:</label>
            <input
              type="text"
              name="policyNumber"
              className={isEditing ? "" : "read-only"}
              value={newBilling.group_policy_number}
              onChange={(e) =>
                setNewBilling({
                  ...newBilling,
                  group_policy_number: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p style={{ margin: 0 }}>{dbBilling.group_policy_number}</p>
        )}

        {isAdmin && editBill && isEditing ? (
          <>
            <label htmlFor="name-on-insurance">Name on Insurance:</label>
            <input
              type="text"
              name="nameOnInsurance"
              className={isEditing ? "" : "read-only"}
              value={newBilling.name_on_insurance}
              onChange={(e) =>
                setNewBilling({
                  ...newBilling,
                  name_on_insurance: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p style={{ margin: 0 }}>{dbBilling.name_on_insurance}</p>
        )}

        {/* The Add button inside the container */}
        {isAdmin && editBill && isEditing && (
          <div className="flex-space-between" style={{ alignItems: "center" }}>
            <button
              aria-label="Add"
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                marginLeft: "0",
              }}
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateBilling()}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const NewInsurance = ({
  isAdmin,
  setBillingInfo,
  billingInfo,
  isEditing,
  billings,
  setBillings,
  postBilling,
  bill,
}) => {
  return (
    <div
      className="insurance-table-container"
      style={{ margin: "0", padding: "0" }}
    >
      {/* Insurance */}
      <div className="mobile-heading">
        <h1>Insurance</h1>{" "}
      </div>
      <div className="mobile-inputs">
        {isAdmin && isEditing ? (
          <>
            <label htmlFor="provider">Provider:</label>
            <input
              type="text"
              name="provider"
              className={isEditing ? "" : "read-only"}
              value={billingInfo.provider}
              onChange={(e) =>
                setBillingInfo({
                  ...billingInfo,
                  provider: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p>{bill.provider}</p>
        )}

        {isAdmin && isEditing ? (
          <>
            <label htmlFor="policy-number">Policy Number:</label>

            <input
              type="text"
              name="policyNumber"
              className={isEditing ? "" : "read-only"}
              value={billingInfo.policyNumber}
              onChange={(e) =>
                setBillingInfo({
                  ...billingInfo,
                  policyNumber: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p>{bill.group_policy_number}</p>
        )}

        {isAdmin && isEditing ? (
          <>
            <label htmlFor="name-on-insurance">Name on Insurance:</label>
            <input
              type="text"
              name="nameOnInsurance"
              className={isEditing ? "" : "read-only"}
              value={billingInfo.nameOnInsurance}
              onChange={(e) =>
                setBillingInfo({
                  ...billingInfo,
                  nameOnInsurance: e.target.value,
                })
              }
            />
          </>
        ) : (
          <p>{bill.name_on_insurance}</p>
        )}

        {/* The Add button inside the container */}
        {isAdmin && isEditing && (
          <button
            className="medication-add-btn"
            onClick={() => {
              const billingPayload = {
                type: "Insurance",
              };

              const insurancePayload = {
                provider: billingInfo.provider,
                group_policy_number: billingInfo.policyNumber,
                name_on_insurance: billingInfo.nameOnInsurance,
              };

              postBilling("Insurance", billingPayload, insurancePayload);
            }}
          >
            Add
          </button>
        )}
      </div>
    </div>
  );
};

export default Billing;
