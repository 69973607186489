import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpatePharmacy, deletePharmacy } from "../../../utils/api";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";

const Pharmacy = ({ item, pharmacy, setPharmacy }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbPharmacy, setDbPharmacy] = useState({
    name: item.name,
    phone_number: item.phone_number,
    address: item.address,
  });

  const [pharm, setPharm] = useState({
    name: item.name,
    phone_number: item.phone_number,
    address: item.address,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    if (type === "phone_number") {
      let num = replaceSpecialChar(e.target.value);
      const phone = formatPhone(num);

      setPharm((prev) => ({
        ...prev,
        [type]: phone,
      }));
    } else {
      setPharm((prev) => ({
        ...prev,
        [type]: value,
      }));
    }
  };

  const updateEntry = () => {
    const payload = {};

    if (pharm.name !== dbPharmacy.name) {
      const column = `name`;
      payload[column] = pharm.name;
    }
    if (pharm.phone_number !== dbPharmacy.phone_number) {
      const column = `phone_number`;
      payload[column] = pharm.phone_number;
    }
    if (pharm.address !== dbPharmacy.address) {
      const column = `address`;
      payload[column] = pharm.address;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpatePharmacy(item.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbPharmacy(pharm);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    const updated = [];

    pharmacy.map((pharma) => {
      if (pharma.id !== item.id) {
        updated.push(pharma);
      }
    });

    deletePharmacy(item.id)
      .then((res) => res.json())
      .then((data) => {
        setPharmacy(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(pharm, dbPharmacy) ? setCanUpdate(false) : setCanUpdate(true);
  }, [pharm]);

  return (
    <>
      {isEditing === false ? (
        <tr>
          <td className="pharm-col-width">{pharm.name}</td>
          <td className="pharm-col-width">{pharm.phone_number}</td>
          <td className="pharm-col-width">{pharm.address}</td>
          <td className="edit-trash-container w-100">
            {" "}
            <button
              className="medication-edit-btn"
              id="bj-edit-btn"
              aria-label="edit"
            >
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              className="medication-delete-btn"
              id="bj-delete-btn"
              aria-label="delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <Icon icon="bi:trash3" />
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td className="pharm-col-width">
            <input
              type="text"
              name="pharmacyName"
              value={pharm.name}
              onChange={(e) => handleInputChange(e, "name")}
            />
          </td>
          <td className="pharm-col-width">
            <input
              type="text"
              name="phoneNumber"
              value={pharm.phone_number}
              onChange={(e) => {
                handleInputChange(e, "phone_number");
              }}
            />
          </td>
          <td className="pharm-col-width">
            <input
              type="text"
              name="address"
              value={pharm.address}
              onChange={(e) => handleInputChange(e, "address")}
            />
          </td>
          <td className="flex-space-between w-100">
            <button
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              <Icon icon="material-symbols:close" />
            </button>
          </td>
        </tr>
      )}
    </>
  );
};

export default Pharmacy;
