// nqMedHistoryState.js

export const initialNqMedHistoryState = {
    high_blood_pressure: false,
    high_blood_pressure_text: "",
    kidney_disease: false,
    kidney_disease_text: "",
    heart_trouble: false,
    heart_trouble_text: "",
    abdominal_bleeding: false,
    abdominal_bleeding_text: "",
    chest_pain: false,
    chest_pain_text: "",
    stroke: false,
    stroke_text: "",
    irregular_hb: false,
    irregular_hb_text: "",
    epilepsy: false,
    epilepsy_text: "",
    congestive_failure: false,
    congestive_failure_text: "",
    broken_bones: false,
    broken_bones_text: "",
    abnormal_cardiogram: false,
    abnormal_cardiogram_text: "",
    back_trouble: false,
    back_trouble_text: "",
    gastric_esophageal: false,
    gastric_esophageal_text: "",
    unusual_muscle_problems: false,
    unusual_muscle_problems_text: "",
    recent_cold: false,
    recent_cold_text: "",
    unexpected_fevers: false,
    unexpected_fevers_text: "",
    asthma: false,
    asthma_text: "",
    bad_reaction: false,
    bad_reaction_text: "",
    abdominal: false,
    abdominal_text: "",
    relatives_with_bad_reaction: false,
    relatives_with_bad_reaction_text: "",
    diabetes: false,
    diabetes_text: "",
    motion_sickness: false,
    motion_sickness_text: "",
    yellow_jaundice: false,
    yellow_jaundice_text: "",
    neuro: false,
    neuro_text: "",
    hepatitis: false,
    hepatitis_text: "",
    hypertension: false,
    hypertension_text: "",
    fainting: false,
    fainting_text: "",
    bleeding_problems: false,
    bleeding_problems_text: "",
    heart_disease: false,
    heart_disease_text: "",
    dentures: false,
    dentures_text: "",
    smoke: false,
    smoke_text: "",
    teeth_caps: false,
    teeth_caps_text: "",
    exercise: false,
    exercise_text: "",
    drink_alcohol: false,
    drink_alcohol_text: "",
    physical_limitations: false,
    physical_limitations_text: "",
  };



  