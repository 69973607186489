import React, { useState, useEffect } from "react";
import { updateNQMedHistory } from "../../../utils/api";

const Question = ({
  question,
  ariaId,
  nqMedHistories = [],
  nqMedHistoryId,
  setNqMedHistories,
}) => {
  let text = question.text === "0" ? "" : question.text;
  const [selectedOption, setSelectedOption] = useState(false);
  const [dbValue, setDbValue] = useState({
    value: question.value,
    text: text,
  });

  const [additionalInfo, setAdditionalInfo] = useState(text || "");
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  // Effect to update state when the question prop changes
  useEffect(() => {
    setSelectedOption(question.value);
    setDbValue({ value: question.value });
    setAdditionalInfo(question.text === "0" ? "" : question.text);
  }, [question]);

  // Handle changes in the select dropdown (Yes/No options)
  const handleSelectChange = (e) => {
    const value = e.target.value === "true";
    setSelectedOption(value);
    value !== dbValue.value ? setCanUpdate(true) : setCanUpdate(false);
  };

  // Handle changes in the additional information input field
  const handleInputChange = (e) => {
    setAdditionalInfo(e.target.value);
    e.target.value !== dbValue.text ? setCanUpdate(true) : setCanUpdate(false);
  };

  // Function to add or update the entry in the medical history
  const addEntry = () => {
    const updatedHistory = nqMedHistories.map((history) => {
      if (history.id === nqMedHistoryId) {
        return {
          ...history,
          [question.db]: selectedOption,
          [`${question.db}_text`]: additionalInfo,
        };
      }
      return history;
    });

    const payload = {
      [question.db]: selectedOption,
      [`${question.db}_text`]: additionalInfo,
    };

    setNqMedHistories(updatedHistory);

    // Make API call to update the medical history in the database
    updateNQMedHistory(nqMedHistoryId, payload)
      .then((res) => {
        if (res === null) {
        } else {
          console.log(res);
          setCanUpdate(false);
        }
      })
      .catch((err) => {
        console.error("Error updating NQMedHistory:", err);
        setErrMessage(true);
      });
  };

  return (
    <div className="question-row">
      <div className="question-container">
        <label htmlFor={ariaId}>{question.label}</label>
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          id={ariaId}
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>

      {selectedOption.toString() === "true" && (
        <input
          type="text"
          id="answer"
          className="full-width-input"
          placeholder="Please provide additional information"
          value={additionalInfo}
          onChange={handleInputChange}
        />
      )}

      {canUpdate && (
        <button className="med-history-add-btn" onClick={addEntry}>
          Update
        </button>
      )}

      {errMessage && <p className="err-message">Error updating history.</p>}
    </div>
  );
};

const Questionnaire = ({
  nqMedHistory,
  nQ,
  recentlyTraveled,
  setRecentlyTraveled,
}) => {
  const [sortedQuestions, setSortedQuestions] = useState({
    haveYouHad: [],
    haveYouHadAdd: [],
    doYou: [],
  });
  // State for the medical history array

  const [nqMedHistories, setNqMedHistories] = useState(
    nQ?.nqMedHistories || []
  );
  // Update medical histories if the nQ object changes

  useEffect(() => {
    if (nQ && nQ.nqMedHistories) {
      setNqMedHistories(nQ.nqMedHistories);
    }
  }, [nQ]);

  useEffect(() => {
    // Prevent running logic if nqMedHistory is undefined
    if (!nqMedHistory) return;

    const isSmallScreen = window.innerWidth < 825;
    // Section 1 - Have You Had
    const haveYouHadQuestions = [
      {
        id: 1,
        label: "1. High Blood Pressure",
        value: nqMedHistory.high_blood_pressure || false,
        text: nqMedHistory.high_blood_pressure_text || "",
        db: "high_blood_pressure",
      },
      {
        id: 13,
        label: "9. Kidney Disease",
        value: nqMedHistory.kidney_disease || false,
        text: nqMedHistory.kidney_disease_text || "",
        db: "kidney_disease",
      },
      {
        id: 2,
        label: "2. Heart Trouble or Heart Attack",
        value: nqMedHistory.heart_trouble || false,
        text: nqMedHistory.heart_trouble_text || "",
        db: "heart_trouble",
      },
      {
        id: 14,
        label: "10. Abdominal Bleeding Problems",
        value: nqMedHistory.abdominal_bleeding || false,
        text: nqMedHistory.abdominal_bleeding_text || "",
        db: "abdominal_bleeding",
      },
      {
        id: 3,
        label: "a. Chest pain or Angina",
        value: nqMedHistory.chest_pain || false,
        text: nqMedHistory.chest_pain_text || "",
        db: "chest_pain",
      },
      {
        id: 15,
        label: "11. Stroke, numbness, or weakness",
        value: nqMedHistory.stroke || false,
        text: nqMedHistory.stroke_text || "",
        db: "stroke",
      },
      {
        id: 4,
        label: "b. Irregular Heart Beat",
        value: nqMedHistory.irregular_hb || false,
        text: nqMedHistory.irregular_hb_text || "",
        db: "irregular_hb",
      },
      {
        id: 16,
        label: "12. Epilepsy or convulsive seizures",
        value: nqMedHistory.epilepsy || false,
        text: nqMedHistory.epilepsy_text || "",
        db: "epilepsy",
      },
      {
        id: 5,
        label: "c. Congestive Heart Failure",
        value: nqMedHistory.congestive_failure || false,
        text: nqMedHistory.congestive_failure_text || "",
        db: "congestive_failure",
      },
      {
        id: 17,
        label: "13. Broken bones of back, neck, or face",
        value: nqMedHistory.broken_bones || false,
        text: nqMedHistory.broken_bones_text || "",
        db: "broken_bones",
      },
      {
        id: 6,
        label: "d. Abnormal Electrocardiogram",
        value: nqMedHistory.abnormal_cardiogram || false,
        text: nqMedHistory.abnormal_cardiogram_text || "",
        db: "abnormal_cardiogram",
      },
      {
        id: 18,
        label: "14. Back Trouble",
        value: nqMedHistory.back_trouble || false,
        text: nqMedHistory.back_trouble_text || "",
        db: "back_trouble",
      },
      {
        id: 7,
        label: "3. Gastric Esophageal Reflux, Hiatal Hernia, Ulcers",
        value: nqMedHistory.gastric_esophageal || false,
        text: nqMedHistory.gastric_esophageal_text || "",
        db: "gastric_esophageal",
      },
      {
        id: 19,
        label: "15. Unusual muscle problems or diseases",
        value: nqMedHistory.unusual_muscle_problems || false,
        text: nqMedHistory.unusual_muscle_problems_text || "",
        db: "unusual_muscle_problems",
      },
      {
        id: 8,
        label: "4. Recent cold, cough, or sore throat",
        value: nqMedHistory.recent_cold || false,
        text: nqMedHistory.recent_cold_text || "",
        db: "recent_cold",
      },
      {
        id: 20,
        label: "16. Unexpected fevers or heat strokes",
        value: nqMedHistory.unexpected_fevers || false,
        text: nqMedHistory.unexpected_fevers_text || "",
        db: "unexpected_fevers",
      },
      {
        id: 9,
        label: "5. Asthma, Emphysema, Bronchitis, or Breathing problems",
        value: nqMedHistory.asthma || false,
        text: nqMedHistory.asthma_text || "",
        db: "asthma",
      },
      {
        id: 21,
        label: "17. Bad reaction to anesthesia",
        value: nqMedHistory.bad_reaction || false,
        text: nqMedHistory.bad_reaction_text || "",
        db: "bad_reaction",
      },
      {
        id: 10,
        label: "6. Abdominal Chest X-ray",
        value: nqMedHistory.abdominal || false,
        text: nqMedHistory.abdominal_text || "",
        db: "abdominal",
      },
      {
        id: 22,
        label: "18. Relatives with bad reaction to anesthesia",
        value: nqMedHistory.relatives_with_bad_reaction || false,
        text: nqMedHistory.relatives_with_bad_reaction_text || "",
        db: "relatives_with_bad_reaction",
      },
      {
        id: 11,
        label: "7. Diabetes",
        value: nqMedHistory.diabetes || false,
        text: nqMedHistory.diabetes_text || "",
        db: "diabetes",
      },
      {
        id: 23,
        label: "19. Problems with motion sickness",
        value: nqMedHistory.motion_sickness || false,
        text: nqMedHistory.motion_sickness_text || "",
        db: "motion_sickness",
      },
      {
        id: 12,
        label: "8. Yellow Jaundice or Hepatitis",
        value: nqMedHistory.yellow_jaundice || false,
        text: nqMedHistory.yellow_jaundice_text || "",
        db: "yellow_jaundice",
      },
    ];
    // Section 1 - Have You Had Additional

    const haveYouHadQuestionsAdd = [
      {
        id: 1,
        label: "1.  Neurological Problems",
        value: nqMedHistory.neuro || false,
        text: nqMedHistory.neuro_text || "",
        db: "neuro",
      },
      {
        id: 4,
        label: "4.  Hepatitis",
        value: nqMedHistory.hepatitis || false,
        text: nqMedHistory.hepatitis_text || "",
        db: "hepatitis",
      },
      {
        id: 2,
        label: "2.  Hypertension",
        value: nqMedHistory.hypertension || false,
        text: nqMedHistory.hypertension_text || "",
        db: "hypertension",
      },
      {
        id: 5,
        label: "5.  Fainting/Diziness",
        value: nqMedHistory.fainting || false,
        text: nqMedHistory.fainting_text || "",
        db: "fainting",
      },
      {
        id: 3,
        label: "3.  Bleeding Problems",
        value: nqMedHistory.bleeding_problems || false,
        text: nqMedHistory.bleeding_problems_text || "",
        db: "bleeding_problems",
      },
      {
        id: 6,
        label: "6.  Heart Disease",
        value: nqMedHistory.heart_disease || false,
        text: nqMedHistory.heart_disease_text || "",
        db: "heart_disease",
      },
    ];
    // Section 1 - Do you

    const doYouQuestions = [
      {
        id: 1,
        label: "1.  Wear Dentures",
        value: nqMedHistory.dentures || false,
        text: nqMedHistory.dentures_text || "",
        db: "dentures",
      },
      {
        id: 4,
        label: "4.  Smoke (how much per day/week)",
        value: nqMedHistory.smoke || false,
        text: nqMedHistory.smoke_text || "",
        db: "smoke",
      },
      {
        id: 2,
        label: "2.  Have caps on teeth",
        value: nqMedHistory.teeth_caps || false,
        text: nqMedHistory.teeth_caps_text || "",
        db: "teeth_caps",
      },
      {
        id: 5,
        label: "5.  Exercise or have strenuous activity",
        value: nqMedHistory.exercise || false,
        text: nqMedHistory.exercise_text || "",
        db: "exercise",
      },
      {
        id: 3,
        label: "3.  Drink Alcohol",
        value: nqMedHistory.drink_alcohol || false,
        text: nqMedHistory.drink_alcohol_text || "",
        db: "drink_alcohol",
      },
      {
        id: 6,
        label: "6.  Have physical limitations",
        value: nqMedHistory.physical_limitations || false,
        text: nqMedHistory.physical_limitations_text || "",
        db: "physical_limitations",
      },
    ];

    if (isSmallScreen) {
      setSortedQuestions({
        haveYouHad: haveYouHadQuestions.sort((a, b) => a.id - b.id),
        haveYouHadAdd: haveYouHadQuestionsAdd.sort((a, b) => a.id - b.id),
        doYou: doYouQuestions.sort((a, b) => a.id - b.id),
      });
    } else {
      setSortedQuestions({
        haveYouHad: haveYouHadQuestions,
        haveYouHadAdd: haveYouHadQuestionsAdd,
        doYou: doYouQuestions,
      });
    }
  }, [nqMedHistory]);
  // Return loading state if nqMedHistory or nQ is not yet available
  if (!nqMedHistory || !nQ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="questionnaire-container">
      <div className="question-section travel">
        {" "}
        <label htmlFor="travel">
          Have you travelled anywhere in the past 30 days?
        </label>
        <select
          id="travel"
          value={recentlyTraveled}
          onChange={(e) => setRecentlyTraveled(e.target.value)}
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>
      <div className="question-section">
        <h3>Have You Had:</h3>
        <div className="question-grid">
          {sortedQuestions.haveYouHad?.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              ariaId={`haveYouHad-question-${index}`}
              nqMedHistories={nqMedHistories}
              nqMedHistoryId={nQ.id}
              setNqMedHistories={setNqMedHistories}
            />
          ))}
        </div>
      </div>
      <div className="question-section">
        <h3>Have You Had (Additional):</h3>
        <div className="question-grid">
          {sortedQuestions.haveYouHadAdd?.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              ariaId={`haveYouHadAdd-question-${index}`}
              nqMedHistories={nqMedHistories}
              nqMedHistoryId={nQ.id}
              setNqMedHistories={setNqMedHistories}
            />
          ))}
        </div>
      </div>
      <div className="question-section">
        <h3>Do You:</h3>
        <div className="question-grid">
          {sortedQuestions.doYou?.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              ariaId={`doYou-question-${index}`}
              nqMedHistories={nqMedHistories}
              nqMedHistoryId={nQ.id}
              setNqMedHistories={setNqMedHistories}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
