import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import Questionnaire from "../NursingQuestionnaire/Questionnaire.js";
import BottomQuestionnaire from "../NursingQuestionnaire/BottomQuestionnaire.js";
import ToggleSwitch from "../NursingQuestionnaire/ToggleSwitch.js";
import PatientInfoQuestions from "../NursingQuestionnaire/PatientInfoQues.js";
import auth from "../../../utils/auth";
import {
  getPatientById,
  getPatientInformation,
  addMedicine,
  addMedAllergy,
  addFoodAllergy,
  addPriorSurgery,
  addPharmacy,
  udpatePatientInformation,
  createBilling,
  createInsurance,
  getBilling,
} from "../../../utils/api";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";

import MedicationAllergies from "./med-allergies";
import CurrentMedications from "./medications";
import Pharmacy from "./pharmacy";
import FoodAllergies from "./food-allergies";
import Surgery from "./surgery";
import Billing from "./billing";

// Patient Info Mobile Menu
const MobileMenu = () => {
  const { id } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState([]); // Patient Details (id's)
  const [pI, setpI] = useState([]); // "Patient Information" from the db

  const [medications, setMedications] = useState([]);
  const [pharmacy, setPharmacy] = useState([]);
  const [medHistory, setMedHistory] = useState([]);
  const [medAllergies, setMedAllergies] = useState([]);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [nfa, setNfa] = useState(false);
  const [nma, setNma] = useState(false);
  const [billings, setBillings] = useState([]);

  // Hold Updates to form (user input)
  const [newEntry, setNewEntry] = useState({
    medications: {
      name: "",
      dose: "",
      route: "",
      frequency: "",
      status: "Active",
    },
    medAllergies: { medication: "", reaction: "" },
    foodAllergies: { food: "", reaction: "" },
    surgeries: { surgery_name: "" },
    pharmacy: { name: "", phone_number: "", address: "" },
  });

  const handleInputChange = (e, type, field = null) => {
    const value = e.target.value;
    setNewEntry((prev) => ({
      ...prev,
      [type]: field ? { ...prev[type], [field]: value } : value,
    }));
  };

  const addEntry = (type) => {
    if (type === "medications") {
      newEntry.medications.patient_info_id = pI.id;

      addMedicine(newEntry.medications)
        .then((res) => res.json())
        .then((data) => {
          newEntry.medications.id = data.newMedications.id;
          setMedications((prev) => [...prev, newEntry.medications]);

          setNewEntry((prev) => ({
            ...prev,
            medications: {
              name: "",
              dose: "",
              route: "",
              frequency: "",
              status: "Active",
            },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "surgeries") {
      newEntry.surgeries.patient_info_id = pI.id;

      addPriorSurgery(newEntry.surgeries)
        .then((res) => res.json())
        .then((data) => {
          newEntry.surgeries.id = data.newPriorSurgeries.id;

          setSurgeries((prev) => [...prev, newEntry.surgeries]);
          setNewEntry((prev) => ({ ...prev, surgeries: { surgery_name: "" } }));
        })
        .catch((err) => console.log(err));
    } else if (type === "medAllergies") {
      newEntry.medAllergies.patient_info_id = pI.id;

      addMedAllergy(newEntry.medAllergies)
        .then((res) => res.json())
        .then((data) => {
          newEntry.medAllergies.id = data.newMedAllergies.id;

          setMedAllergies((prev) => [...prev, newEntry.medAllergies]);
          setNewEntry((prev) => ({
            ...prev,
            medAllergies: { medication: "", reaction: "" },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "foodAllergies") {
      newEntry.foodAllergies.patient_info_id = pI.id;

      addFoodAllergy(newEntry.foodAllergies)
        .then((res) => res.json())
        .then((data) => {
          newEntry.foodAllergies.id = data.newFoodAllergies.id;

          setFoodAllergies((prev) => [...prev, newEntry.foodAllergies]);
          setNewEntry((prev) => ({
            ...prev,
            foodAllergies: { food: "", reaction: "" },
          }));
        })
        .catch((err) => console.log(err));
    } else if (type === "pharmacy") {
      newEntry.pharmacy.patient_info_id = pI.id;

      addPharmacy(newEntry.pharmacy)
        .then((res) => res.json())
        .then((data) => {
          newEntry.pharmacy.id = data.newPharmacy.id;

          setPharmacy((prev) => [...prev, newEntry.pharmacy]);
          setNewEntry((prev) => ({
            ...prev,
            pharmacy: { name: "", phone_number: "", address: "" },
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const updatePI = (e, type) => {
    const value = e.target.checked;

    if (type === "medAllergies") {
      const payload = { NKMA: value };
      udpatePatientInformation(pI.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setNma(value);
        })
        .catch((err) => console.log(err));
    } else if (type === "foodAllergies") {
      const payload = { NKFA: value };
      udpatePatientInformation(pI.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setNfa(value);
        })
        .catch((err) => console.log(err));
    }
  };

  const postBilling = (type, billingPayload, typePayload) => {
    billingPayload.patient_info_id = pI.id;

    createBilling(billingPayload)
      .then((res) => res.json())
      .then((data) => {
        switch (type) {
          case "Insurance":
            typePayload.billing_id = data.newBilling.id;

            createInsurance(typePayload)
              .then((res) => res.json())
              .then((insData) => {
                getBilling(insData.newInsurance.billing_id)
                  .then((res) => res.json())
                  .then((data) => {
                    setBillings([...billings, data]);
                  });
              })
              .catch((err) => console.log(err));
            break;
          default:
            return;
        }
      })
      .catch((err) => console.log(err));
  };

  // Fetch user role and check if admin
  useEffect(() => {
    // Get user from JWT
    const user = auth.getUser();

    if (!user || !user.id) {
      return;
    }

    if (user.role === "Admin") {
      // Set isAdmin if user is an admin
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    // Fetch patient data by id
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        setSelectedPatient(data);

        // Fetch Patient Information by id
        getPatientInformation(data.patientInformation.id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            // Save a copy of original data
            setpI(data);

            // Update Display State
            setMedications(data.medications);
            setMedAllergies(data.medAllergies);
            setFoodAllergies(data.foodAllergies);
            setSurgeries(data.priorSurgeries);
            setPharmacy(data.pharmacies);
            setMedHistory(data.medHistories[0]);
            setNfa(data.NKFA);
            setNma(data.NKMA);
            setBillings(data.billings);

            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {" "}
      <div className="patient-info-form">
        {/* Current Medications */}
        <div className="mobile-container">
          <div className="mobile-border">
            <div className="mobile-heading">
              <h1>Current Medications:</h1>
            </div>
            {medications.map((med, index) => (
              <CurrentMedications
                med={med}
                key={med.id}
                medications={medications}
                setMedications={setMedications}
              />
            ))}
            <div className="mobile-inputs" style={{ marginTop: "30px" }}>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                type="text"
                name="name"
                value={newEntry.medications.name}
                onChange={(e) => handleInputChange(e, "medications", "name")}
              />
              <label htmlFor="dose">Dose:</label>
              <input
                id="dose"
                type="text"
                name="dose"
                value={newEntry.medications.dose}
                onChange={(e) => handleInputChange(e, "medications", "dose")}
              />
              <label htmlFor="route">Route:</label>
              <input
                id="route"
                type="text"
                name="route"
                value={newEntry.medications.route}
                onChange={(e) => handleInputChange(e, "medications", "route")}
              />
              <label htmlFor="frequency">Frequency:</label>
              <input
                id="frequency"
                type="text"
                name="frequency"
                value={newEntry.medications.frequency}
                onChange={(e) =>
                  handleInputChange(e, "medications", "frequency")
                }
              />
              <label htmlFor="status">Status</label>
              <select
                id="status"
                name="status"
                value={newEntry.medications.status}
                onChange={(e) => handleInputChange(e, "medications", "status")}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>

              <button
                aria-label="Add"
                className="medication-add-btn"
                onClick={() => addEntry("medications")}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* Medication Allergies */}
        <div className="mobile-container">
          <div className="mobile-border">
            <div className="mobile-heading">
              <h1>Medication Allergies:</h1>{" "}
              {medAllergies.length === 0 ? (
                <label>
                  <input
                    type="checkbox"
                    checked={nma}
                    onChange={(e) => {
                      handleInputChange(e, "medAllergies", "nka");
                      updatePI(e, "medAllergies");
                    }}
                  />
                  NKA
                </label>
              ) : (
                <></>
              )}
            </div>
            {medAllergies.map((allergy, index) => (
              <MedicationAllergies
                key={allergy.id}
                allergy={allergy}
                setMedAllergies={setMedAllergies}
                medAllergies={medAllergies}
              />
            ))}
            <div
              className={nma ? "mobile-inputs dimmed" : "mobile-inputs"}
              style={{ marginTop: "30px" }}
            >
              <label>Medication:</label>
              <input
                type="text"
                name="medication"
                value={newEntry.medAllergies.medication}
                onChange={(e) =>
                  handleInputChange(e, "medAllergies", "medication")
                }
              />
              <label>Reaction:</label>
              <input
                type="text"
                name="reaction"
                value={newEntry.medAllergies.reaction}
                onChange={(e) =>
                  handleInputChange(e, "medAllergies", "reaction")
                }
              />

              <button
                aria-label="Add"
                className="medication-add-btn"
                onClick={() => addEntry("medAllergies")}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* Food Allergies */}
        <div className="mobile-container">
          <div className="mobile-border">
            <div className="mobile-heading">
              <h1>Food Allergies:</h1>{" "}
              {foodAllergies.length === 0 ? (
                <label>
                  <input
                    type="checkbox"
                    checked={nfa}
                    onChange={(e) => {
                      handleInputChange(e, "foodAllergies", "nka");
                      updatePI(e, "foodAllergies");
                    }}
                  />{" "}
                  NKA
                </label>
              ) : (
                <></>
              )}
            </div>
            {foodAllergies.map((allergy, index) => (
              <FoodAllergies
                key={allergy.id}
                allergy={allergy}
                setFoodAllergies={setFoodAllergies}
                foodAllergies={foodAllergies}
              />
            ))}
            <div
              style={{ marginTop: "30px" }}
              className={nfa ? "mobile-inputs dimmed" : "mobile-inputs"}
            >
              <label>Food:</label>
              <input
                type="text"
                name="food"
                value={newEntry.foodAllergies.food}
                onChange={(e) => handleInputChange(e, "foodAllergies", "food")}
              />
              <label>Reaction:</label>
              <input
                type="text"
                name="reaction"
                value={newEntry.foodAllergies.reaction}
                onChange={(e) =>
                  handleInputChange(e, "foodAllergies", "reaction")
                }
              />

              <button
                aria-label="Add"
                className="medication-add-btn"
                onClick={() => addEntry("foodAllergies")}
              >
                Add
              </button>
            </div>
          </div>

          {/* Prior Surgeries */}
          <div className="mobile-border prior-surgeries">
            <div className="mobile-heading">
              <h1>Prior Surgeries</h1>
            </div>
            {surgeries.map((surgery, index) => (
              <Surgery
                surgery={surgery}
                key={surgery.id}
                surgeries={surgeries}
                setSurgeries={setSurgeries}
              />
            ))}
            <div className="mobile-inputs" style={{ marginTop: "30px" }}>
              <input
                type="text"
                placeholder="Enter surgery"
                value={newEntry.surgeries.surgery_name}
                onChange={(e) =>
                  handleInputChange(e, "surgeries", "surgery_name")
                }
              />
              <button
                aria-label="Add"
                className="medication-add-btn"
                onClick={() => addEntry("surgeries")}
              >
                Add
              </button>
            </div>
          </div>

          {/* Symptoms */}
          <div className="medical-conditions-container">
            <PatientInfoQuestions medHistory={medHistory} />
          </div>
        </div>

        {/* Insurance */}
        <div className="mobile-container" style={{ marginBottom: "40px" }}>
          <div className="mobile-border">
            <Billing
              isAdmin={isAdmin}
              billings={billings}
              setBillings={setBillings}
              postBilling={postBilling}
            />
          </div>
        </div>

        {/* Preferred Pharmacy */}
        <div className="mobile-container">
          <div className="mobile-border">
            <div className="mobile-heading">
              <h1>Preferred Pharmacy</h1>{" "}
            </div>

            {pharmacy.map((pharm, index) => (
              <Pharmacy
                key={pharm.id}
                item={pharm}
                setPharmacy={setPharmacy}
                pharmacy={pharmacy}
              />
            ))}

            <div className="mobile-inputs" style={{ marginTop: "30px" }}>
              <label htmlFor="pharmacyName">Name:</label>
              <input
                id="pharmacyName"
                type="text"
                name="pharmacyName"
                value={newEntry.pharmacy.name}
                onChange={(e) => handleInputChange(e, "pharmacy", "name")}
              />
              <label htmlFor="phone-number">Phone Number:</label>
              <input
                id="phone-number"
                type="text"
                name="phoneNumber"
                value={newEntry.pharmacy.phone_number}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);

                  const updatedPhone = {
                    ...newEntry.pharmacy,
                    phone_number: phone,
                  };

                  setNewEntry({
                    ...newEntry,
                    pharmacy: updatedPhone,
                  });
                }}
              />
              <label htmlFor="pharmAddress">Address:</label>
              <input
                id="pharmAddress"
                type="text"
                name="address"
                value={newEntry.pharmacy.address}
                onChange={(e) => handleInputChange(e, "pharmacy", "address")}
              />{" "}
              <button
                className="medication-add-btn"
                aria-label="Add"
                onClick={() => addEntry("pharmacy")}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Nursing Questionnaire Mobile Menu
export const MobileMenuTwo = () => {
  // state to toggle between metric and standard
  const [isMetric, setIsMetric] = useState(false);
  const [height, setHeight] = useState({ feet: "0", inches: "0", cm: "0" });
  const [weight, setWeight] = useState({ pounds: "0", kg: "0" });

  const handleToggle = (active) => {
    // Update metric state
    setIsMetric(active);

    if (active) {
      // Convert to metric
      const totalInches =
        parseFloat(height.feet || 0) * 12 + parseFloat(height.inches || 0);
      const cm = (totalInches * 2.54).toFixed(2);
      setHeight({ ...height, cm });

      const kg = (parseFloat(weight.pounds || 0) / 2.20462).toFixed(2);
      setWeight({ ...weight, kg });
    } else {
      // Convert back to standard
      const inches = parseFloat(height.cm || 0) / 2.54;
      const feet = Math.floor(inches / 12);
      const inch = (inches % 12).toFixed(2);
      setHeight({ feet, inches: inch });

      const pounds = (parseFloat(weight.kg || 0) * 2.20462).toFixed(2);
      setWeight({ ...weight, pounds });
    }
  };
  const [medications, setMedications] = useState([
    {
      name: "Acetaminophen",
      dose: "325mg",
      route: "Oral",
      frequency: "1 per week",
      status: "Inactive",
    },
  ]);

  const [surgeries, setSurgeries] = useState([]);
  const [medAllergies, setMedAllergies] = useState([]);
  const [foodAllergies, setFoodAllergies] = useState([]);

  const [newEntry, setNewEntry] = useState({
    medications: {
      name: "",
      dose: "",
      route: "",
      frequency: "",
      status: "Active",
    },
    surgeries: "",
    medAllergies: { medication: "", reaction: "" },
    foodAllergies: { food: "", reaction: "" },
  });

  // Handles input changes for medications, surgeries, medication allergies, and food allergies
  const handleInputChange = (e, type, field) => {
    const value = e.target.value;
    setNewEntry((prev) => ({
      ...prev,
      [type]: { ...prev[type], [field]: value },
    }));
  };

  // Adds new entry for medications, surgeries, medication allergies, or food allergies
  const addEntry = (type) => {
    if (type === "medications") {
      setMedications((prev) => [...prev, newEntry.medications]);
      setNewEntry((prev) => ({
        ...prev,
        medications: {
          name: "",
          dose: "",
          route: "",
          frequency: "",
          status: "Active",
        },
      }));
    } else if (type === "surgeries") {
      setSurgeries((prev) => [...prev, { name: newEntry.surgeries }]);
      setNewEntry((prev) => ({ ...prev, surgeries: "" }));
    } else if (type === "medAllergies") {
      setMedAllergies((prev) => [...prev, newEntry.medAllergies]);
      setNewEntry((prev) => ({
        ...prev,
        medAllergies: { medication: "", reaction: "" },
      }));
    } else if (type === "foodAllergies") {
      setFoodAllergies((prev) => [...prev, newEntry.foodAllergies]);
      setNewEntry((prev) => ({
        ...prev,
        foodAllergies: { food: "", reaction: "" },
      }));
    }
  };
  return (
    <>
      {" "}
      <div className="nurse-ques-form">
        <div className="nurse-ques-container">
          <h1>Nursing Questionnaire</h1>
          <div className="border-bottom"></div>

          {/* Height, Weight, and Toggle */}
          <div className="nurse-ques-form-row">
            <div className="nurse-ques-form-group">
              <label htmlFor="height">Height</label>
              <div className="nurse-ques-input-group">
                {!isMetric ? (
                  <>
                    <div className="nurse-ques-field">
                      <input
                        id="height-feet"
                        type="text"
                        placeholder="ft."
                        value={height.feet}
                        onChange={(e) =>
                          setHeight({ ...height, feet: e.target.value })
                        }
                      />
                      <label className="below-label" htmlFor="height-feet">
                        Feet
                      </label>
                    </div>
                    <div className="nurse-ques-field">
                      <input
                        type="text"
                        placeholder="inch."
                        id="height-inches"
                        value={height.inches}
                        onChange={(e) =>
                          setHeight({ ...height, inches: e.target.value })
                        }
                      />
                      <label className="below-label" htmlFor="height-inches">
                        Inches
                      </label>
                    </div>
                  </>
                ) : (
                  <div className="nurse-ques-field">
                    <input
                      id="height-cm"
                      type="text"
                      placeholder="cm"
                      value={height.cm}
                      onChange={(e) =>
                        setHeight({ ...height, cm: e.target.value })
                      }
                    />
                    <label className="below-label" htmlFor="height-cm">
                      Centimeters
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="nurse-ques-form-group">
              <label htmlFor="weight">Weight</label>
              <div className="nurse-ques-field">
                {!isMetric ? (
                  <>
                    <input
                      id="weight"
                      type="text"
                      placeholder="lbs."
                      value={weight.pounds}
                      onChange={(e) =>
                        setWeight({ ...weight, pounds: e.target.value })
                      }
                    />
                    <label className="below-label" htmlFor="weight">
                      Pounds
                    </label>
                  </>
                ) : (
                  <>
                    <input
                      id="weight-kg"
                      type="text"
                      placeholder="kg"
                      value={weight.kg}
                      onChange={(e) =>
                        setWeight({ ...weight, kg: e.target.value })
                      }
                    />
                    <label className="below-label" htmlFor="weight-kg">
                      Kilograms
                    </label>
                  </>
                )}
              </div>
            </div>

            <div className="nurse-ques-form-group switch-group">
              <ToggleSwitch id="toggle" onToggle={handleToggle} />
            </div>
          </div>
          {/* BMI, age, sex, diagnosis */}
          <div className="nurse-ques-form-row bmi">
            <div className="nurse-ques-form-group bmi-group">
              <label htmlFor="bmi">Calculated BMI</label>
              <input type="text" disabled className="bmi-input" id="bmi" />
            </div>
            <div className="nurse-ques-form-group">
              <label htmlFor="age">Age</label>
              <input id="age" type="number" />
            </div>
            <div className="nurse-ques-form-group">
              <label htmlFor="sex">Sex</label>
              <select id="sex">
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <div className="nurse-ques-form-row">
            <div className="nurse-ques-form-group diagnosis-group">
              <label htmlFor="diagnosis">Diagnosis</label>
              <input id="diagnosis" type="text" />
            </div>
          </div>

          {/* Current Medications */}
          <div className="mobile-container">
            <div className="mobile-border">
              <div className="mobile-heading">
                <h1>Current Medications:</h1>
              </div>
              {medications.map((med, index) => (
                <div className="mobile-body">
                  <div key={index}>
                    <p>{med.name}</p>
                    <p>{med.dose}</p>
                    <p>{med.route}</p>
                    <p>{med.frequency}</p>
                  </div>
                  <div className="mobile-icons">
                    <p className={`status-badge ${med.status.toLowerCase()}`}>
                      {med.status}
                    </p>
                    <button className="medication-edit-btn" aria-label="Edit">
                      <Icon icon="typcn:edit" />
                    </button>
                    <button
                      className="medication-delete-btn"
                      aria-label="Delete"
                    >
                      <Icon icon="bi:trash3" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="mobile-inputs">
                <label htmlFor="name">Name:</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={newEntry.medications.name}
                  onChange={(e) => handleInputChange(e, "medications", "name")}
                />
                <label htmlFor="dose">Dose:</label>
                <input
                  id="dose"
                  type="text"
                  name="dose"
                  value={newEntry.medications.dose}
                  onChange={(e) => handleInputChange(e, "medications", "dose")}
                />
                <label htmlFor="route">Route:</label>
                <input
                  id="route"
                  type="text"
                  name="route"
                  value={newEntry.medications.route}
                  onChange={(e) => handleInputChange(e, "medications", "route")}
                />
                <label htmlFor="frequency">Frequency:</label>
                <input
                  id="frequency"
                  type="text"
                  name="frequency"
                  value={newEntry.medications.frequency}
                  onChange={(e) =>
                    handleInputChange(e, "medications", "frequency")
                  }
                />
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  name="status"
                  value={newEntry.medications.status}
                  onChange={(e) =>
                    handleInputChange(e, "medications", "status")
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                <button
                  aria-label="Add"
                  className="medication-add-btn"
                  onClick={() => addEntry("medications")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* Prior Surgeries */}
          <div className="mobile-container prior-surgeries">
            <div className="mobile-border">
              <div className="mobile-heading">
                <h1>Prior Surgeries</h1>
              </div>
              {surgeries.map((surgery, index) => (
                <div className="mobile-body" key={index}>
                  <div>
                    <p>{surgery.name}</p>
                  </div>
                  <div className="mobile-surgery">
                    <button className="surgery-edit-btn" aria-label="Edit">
                      <Icon icon="typcn:edit" />
                    </button>
                    <button className="surgery-delete-btn" aria-label="Delete">
                      <Icon icon="bi:trash3" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="mobile-inputs">
                <input
                  type="text"
                  placeholder="Enter surgery"
                  value={newEntry.surgeries}
                  onChange={(e) => handleInputChange(e, "surgeries")}
                />
                <button
                  aria-label="Add"
                  className="medication-add-btn"
                  onClick={() => addEntry("surgeries")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <Questionnaire />
          {/* Medication Allergies */}
          <div className="mobile-container">
            <div className="mobile-border">
              <div className="mobile-heading">
                <h1>Medication Allergies:</h1>{" "}
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleInputChange(e, "medAllergies", "nka")
                    }
                  />{" "}
                  NKA
                </label>
              </div>
              {medAllergies.map((allergy, index) => (
                <div className="mobile-body">
                  <div key={index}>
                    <p>{allergy.medication}</p>
                    <p>{allergy.reaction}</p>
                  </div>
                  <div className="mobile-icons">
                    <button className="medication-edit-btn" aria-label="Edit">
                      <Icon icon="typcn:edit" />
                    </button>
                    <button
                      className="medication-delete-btn"
                      aria-label="Delete"
                    >
                      <Icon icon="bi:trash3" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="mobile-inputs">
                <label>Medication:</label>
                <input
                  type="text"
                  name="medication"
                  value={newEntry.medAllergies.medication}
                  onChange={(e) =>
                    handleInputChange(e, "medAllergies", "medication")
                  }
                />
                <label>Reaction:</label>
                <input
                  type="text"
                  name="reaction"
                  value={newEntry.medAllergies.reaction}
                  onChange={(e) =>
                    handleInputChange(e, "medAllergies", "reaction")
                  }
                />

                <button
                  aria-label="Add"
                  className="medication-add-btn"
                  onClick={() => addEntry("medAllergies")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* Food Allergies */}
          <div className="mobile-container">
            <div className="mobile-border">
              <div className="mobile-heading">
                <h1>Food Allergies:</h1>{" "}
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleInputChange(e, "foodAllergies", "nka")
                    }
                  />{" "}
                  NKA
                </label>
              </div>
              {foodAllergies.map((allergy, index) => (
                <div className="mobile-body">
                  <div key={index}>
                    <p>{allergy.food}</p>
                    <p>{allergy.reaction}</p>
                  </div>
                  <div className="mobile-icons">
                    <button className="medication-edit-btn" aria-label="Edit">
                      <Icon icon="typcn:edit" />
                    </button>
                    <button
                      className="medication-delete-btn"
                      aria-label="Delete"
                    >
                      <Icon icon="bi:trash3" />
                    </button>
                  </div>
                </div>
              ))}
              <div className="mobile-inputs">
                <label>Food:</label>
                <input
                  type="text"
                  name="food"
                  value={newEntry.foodAllergies.food}
                  onChange={(e) =>
                    handleInputChange(e, "foodAllergies", "food")
                  }
                />
                <label>Reaction:</label>
                <input
                  type="text"
                  name="reaction"
                  value={newEntry.foodAllergies.reaction}
                  onChange={(e) =>
                    handleInputChange(e, "foodAllergies", "reaction")
                  }
                />

                <button
                  aria-label="Add"
                  className="medication-add-btn"
                  onClick={() => addEntry("foodAllergies")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <BottomQuestionnaire />
          <div className="ques-submit-btn-container">
            <button className="ques-submit-btn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
