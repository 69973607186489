import React, { useState, useEffect } from "react";
import "./patientFolder.css";
import { Link, useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import { getPatientById } from "../../../utils/api";
import { formatTime } from "../../../utils/helpers";

const PatientFolder = () => {
  // State for patient info and surgery
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState([]);

  const { id } = useParams();

  // useEffect to fetch patient data by id
  useEffect(() => {
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setSelectedPatient(data);
        // Check if the patient has any surgeries
        if (data.surgeries && data.surgeries.length > 0) {
          setSelectedSurgery(data.surgeries);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Menu />
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column surgery-card">
          {selectedSurgery.length !== 0 ? (
            selectedSurgery.map((surgery) => (
              <div
                key={surgery.id}
                className="position-container hover-pointer"
                onClick={() =>
                  window.location.replace(
                    `/surgery-view/${id}/surgery/${surgery.id}`
                  )
                }
              >
                <div className="surgery-type">
                  {/* surgery type */}
                  <p>{surgery.type}</p>{" "}
                </div>

                <div className="surgery-date-time">
                  <p className="surgery-date">
                    {/* surgery date */}
                    {new Date(surgery.DOS).toLocaleDateString()}
                  </p>
                  {/* surgery time */}
                  <p className="surgery-time">{formatTime(surgery.TOS)}</p>{" "}
                </div>
              </div>
            ))
          ) : (
            // message if no surgeries are available for the patient
            <p>No surgeries available for this patient.</p>
          )}
          <div className="surgery-btn-container">
            <Link to={`/new-surgery-form/${id}`}>
              <button className="surgery-btn">Add New Surgery</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientFolder;
