// ------------- User Fetch Requests -------------
// Signup Page
export const createUser = (userData) => {
  return fetch("/api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// Login Page
export const loginUser = (userData) => {
  return fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// User Profile Pages
export const udpateUser = (userData, user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const userRole = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUserProfile = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTokenImage = (user_id) => {
  return fetch(`/api/users/newImage/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addFile = (formData) => {
  return fetch("/api/uploads/create", {
    method: "POST",
    body: formData,
  });
};
// Admin Pages
export const getUsersbyEmail = (searchTerm) => {
  return fetch(`/api/users/email/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUsersbyName = (searchTerm) => {
  return fetch(`/api/users/name/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPendingUsers = () => {
  return fetch(`/api/users/pending`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRecentUsers = () => {
  return fetch(`/api/users/recent`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//password

export const forgotPassword = async (payload) => {
  return fetch("/api/users/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updatePassword = ({ email, otp, newPassword }) => {
  console.log({ email, otp, newPassword });
  return fetch("/api/users/update-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      otp,
      newPassword,
    }),
  });
};
export const validateOTP = async (payload) => {
  return fetch("/api/users/check-otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Patient Search Page
export const getRecentPatients = () => {
  return fetch(`/api/patients`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRecentSurgeries = () => {
  return fetch(`/api/surgeries`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUsersbyDOS = (searchTerm) => {
  return fetch(`/api/patients/dos?dos=${encodeURIComponent(searchTerm)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getPatientsbyDOB = (searchTerm) => {
  return fetch(`/api/patients/dob?dob=${encodeURIComponent(searchTerm)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const searchPatients = (searchType, searchTerm) => {
  return fetch(
    `/api/patients/search?searchType=${searchType}&searchTerm=${encodeURIComponent(
      searchTerm
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
// Patient Folder
export const getPatientById = (user_id) => {
  return fetch(`/api/patients/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// New Surgery Page
export const getSurgerybyId = (surgery_id) => {
  return fetch(`/api/surgeries/${surgery_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Patient Info
export const getDoctors = () => {
  return fetch(`/api/users/doctor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const postPatientInfo = (newPatient) => {
  return fetch("/api/patients", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPatient),
  });
};
export const createPatientInfoRecord = (payload) => {
  return fetch("/api/patient-info", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const createNursingQuestionnaireRecord = (payload) => {
  return fetch("/api/nursing-quest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Patient Information
export const getPatientInformation = (id) => {
  return fetch(`/api/patient-info/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpatePatientInformation = (id, payload) => {
  return fetch(`/api/patient-info/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addMedicine = (payload) => {
  return fetch(`/api/patient-info/medications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addMedAllergy = (payload) => {
  return fetch(`/api/patient-info/med-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addFoodAllergy = (payload) => {
  return fetch(`/api/patient-info/food-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addPriorSurgery = (payload) => {
  return fetch(`/api/patient-info/prior-surgeries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addPharmacy = (payload) => {
  return fetch(`/api/patient-info/pharmacy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateHistory = (id, payload) => {
  return fetch(`/api/patient-info/med-history/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateMedications = (id, payload) => {
  return fetch(`/api/patient-info/medications/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteMedications = (id) => {
  return fetch(`/api/patient-info/medications/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateMedAllergy = (id, payload) => {
  return fetch(`/api/patient-info/med-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteMedAllergy = (id) => {
  return fetch(`/api/patient-info/med-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpatePharmacy = (id, payload) => {
  return fetch(`/api/patient-info/pharmacy/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deletePharmacy = (id) => {
  return fetch(`/api/patient-info/pharmacy/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateFoodAllergies = (id, payload) => {
  return fetch(`/api/patient-info/food-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteFoodAllergies = (id) => {
  return fetch(`/api/patient-info/food-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateSurgery = (id, payload) => {
  return fetch(`/api/patient-info/prior-surgeries/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteSurgery = (id) => {
  return fetch(`/api/patient-info/prior-surgeries/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const createBilling = (payload) => {
  return fetch(`/api/billing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(payload),
  });
};
export const createInsurance = (payload) => {
  return fetch(`/api/billing/insurance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const udpateInsurance = (id, payload) => {
  return fetch(`/api/billing/insurance/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteBilling = (id) => {
  return fetch(`/api/billing/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBilling = (id) => {
  return fetch(`/api/billing/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// New Surgery Page
export const createSurgery = (payload) => {
  return fetch(`/api/surgeries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Nursing Qustionnaire

export const updatePersonalInfo = (payload) => {
  return fetch(`/api/nursing-quest/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const getNQInformation = (id) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateNQMedications = (id, payload) => {
  return fetch(`/api/nursing-quest/medications/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQMedications = (id) => {
  return fetch(`/api/nursing-quest/medications/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addNQFoodAllergies = (payload) => {
  return fetch(`/api/nursing-quest/food-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addNQMedicine = (payload) => {
  return fetch(`/api/nursing-quest/medications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const addNQMedHistory = (payload) => {
  return fetch(`/api/nursing-quest/med-history`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const addNQPriorSurgeries = (payload) => {
  return fetch(`/api/nursing-quest/prior-surgeries`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const addNQMedAllergies = (payload) => {
  return fetch(`/api/nursing-quest/med-allergies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const udpateNQFoodAllergies = (id, payload) => {
  return fetch(`/api/nursing-quest/food-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQFoodAllergies = (id) => {
  return fetch(`/api/nursing-quest/food-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const udpateNQMedAllergy = (id, payload) => {
  return fetch(`/api/nursing-quest/med-allergies/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQMedAllergy = (id) => {
  return fetch(`/api/nursing-quest/med-allergies/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateNQSurgery = (id, payload) => {
  return fetch(`/api/nursing-quest/prior-surgeries/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const deleteNQSurgery = (id) => {
  return fetch(`/api/nursing-quest/prior-surgeries/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpateNurseQues = (id, payload) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateNQHistory = (id, payload) => {
  return fetch(`/api/nursing-quest/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
export const updateNQMedHistory = (id, payload) => {
  return fetch(`/api/nursing-quest/med-history/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const getNQMedHistory = (id) => {
  return fetch(`/api/nursing-quest/med-history/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const udpatePatient = (id, payload) => {
  return fetch(`/api/patients/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
