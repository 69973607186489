import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { udpateSurgery, deleteSurgery } from "../../../utils/api";

const Surgery = ({ surgery, surgeries, setSurgeries }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const [dbSurg, setDbSurg] = useState({
    surgery_name: surgery.surgery_name,
  });

  const [surg, setSurg] = useState({
    surgery_name: surgery.surgery_name,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    setSurg((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const updateEntry = () => {
    const payload = {};

    if (surg.surgery_name !== dbSurg.surgery_name) {
      const column = `surgery_name`;
      payload[column] = surg.surgery_name;
    }

    if (Object.keys(payload).length !== 0) {
      // console.log(payload);

      udpateSurgery(surgery.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setDbSurg(surg);
          setIsEditing(false);
          setCanUpdate(false);
          setErrMessage(false);
        })
        .catch((err) => {
          console.log(err);
          setErrMessage(true);
        });
    }
  };

  const handleDelete = () => {
    console.log(surgeries);

    const updated = [];

    surgeries.map((item) => {
      if (item.id !== surgery.id) {
        updated.push(item);
      }
    });

    deleteSurgery(surgery.id)
      .then((res) => res.json())
      .then((data) => {
        setSurgeries(updated);
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  // Allow Update IF something has changed
  useEffect(() => {
    const isEqual = (...objects) =>
      objects.every(
        (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
      );

    // This effect will run after every medication update
    isEqual(surg, dbSurg) ? setCanUpdate(false) : setCanUpdate(true);
  }, [surg]);

  return (
    <>
      {isEditing === false ? (
        <div className="mobile-body">
          <div>
            <p>{dbSurg.surgery_name}</p>
          </div>
          <div className="mobile-surgery">
            <button className="surgery-edit-btn" aria-label="Edit">
              <Icon icon="typcn:edit" onClick={() => setIsEditing(true)} />
            </button>
            <button
              className="surgery-delete-btn"
              aria-label="Delete"
              style={{ marginLeft: "30px" }}
            >
              <Icon
                icon="bi:trash3"
                onClick={() => {
                  handleDelete();
                }}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="mobile-inputs">
          <input
            type="text"
            placeholder="Enter surgery"
            value={surg.surgery_name}
            onChange={(e) => handleInputChange(e, "surgery_name")}
          />

          <div className="flex-space-between" style={{ alignItems: "center" }}>
            <button
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                marginLeft: "0",
              }}
              aria-label="Add"
              className={
                canUpdate
                  ? "medication-add-btn update"
                  : "medication-add-btn update disabled"
              }
              onClick={() => updateEntry()}
            >
              Update
            </button>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="medication-close-btn"
              id="bj-close-btn"
              aria-label="close"
              onClick={() => setIsEditing(false)}
            >
              Close <Icon icon="material-symbols:close" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Surgery;
