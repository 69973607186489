import React, { useState, useEffect } from "react";
import "./newpatientform.css";
import BackgroundImage from "../../../components/BackgroundImage";
import {
  postPatientInfo,
  createPatientInfoRecord,
  getDoctors,
  createBilling,
  createInsurance,
  createSurgery,
  createNursingQuestionnaireRecord,
} from "../../../utils/api";
import { replaceSpecialChar, formatPhone } from "../../../utils/helpers";

const NewPatientForm = () => {
  // State for form inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [surgeon, setSurgeon] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dos, setDos] = useState("");
  const [tos, setTos] = useState("");
  const [surgeryType, setSurgeryType] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [billingType, setBillingType] = useState("Insurance");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [nameOnInsurance, setNameOnInsurance] = useState("");
  const [insurances, setInsurances] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [manualInput, setManualInput] = useState(false);
  const [surgeonInput, setSurgeonInput] = useState("");

  // Conditionally add surgery or billing info
  const [createNewSurgery, setcreateSurgery] = useState(false);
  const [createNewBilling, setcreateBilling] = useState(false);

  // State for loading and response message
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newPatient = {
      first_name: firstName,
      last_name: lastName,
      DOB: dob,
      phone_number: phoneNumber,
      surgeon: manualInput === true ? surgeonInput : surgeon,
      preferred_language: preferredLanguage,
    };

    if (email.trim() !== "") {
      newPatient.email = email;
    }

    try {
      const response = await postPatientInfo(newPatient);
      if (response.ok) {
        const data = await response.json();
        const patient_id = data.newPatient.id;

        handleNewPatientInfo(patient_id);
      } else {
        const data = await response.json();

        if (data.message) {
          if (data.message === "Email already in use") {
            setResponseMessage(
              "This email is already in use for another patient. Please try a different email."
            );
          }
        } else {
          setResponseMessage("Failed to add patient. Please try again.");
        }
      }
    } catch (err) {
      console.log(err);
      setResponseMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Create the Patient Information Record
  const handleNewPatientInfo = async (patient_id) => {
    try {
      const response = await createPatientInfoRecord({
        patient_id: patient_id,
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data);

        // Create the Surgery + Billing Records if applicable
        if (createNewSurgery === true && createNewBilling === true) {
          handleNewSurgery(patient_id, false).then(() => {
            handleNewBilling(data, true, patient_id);
          });
        } else if (createNewSurgery === true) {
          handleNewSurgery(patient_id, true);
        } else if (createNewBilling === true) {
          handleNewBilling(data, true, patient_id);
        } else {
          window.location.replace(`/patient/${patient_id}`);
        }

        return;
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding Information to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding Information to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  const handleNewSurgery = async (patient_id, shouldRedirect) => {
    const payload = {
      patient_id: patient_id,
      DOS: dos,
      TOS: tos,
      type: surgeryType,
      physician: manualInput === true ? surgeonInput : surgeon,
    };

    try {
      const response = await createSurgery(payload);
      if (response.ok) {
        const data = await response.json();
        // console.log(data);

        const nQpayload = { surgery_id: data.newSurgery.id };
        const nQresponse = await createNursingQuestionnaireRecord(nQpayload);

        if (nQresponse.ok) {
          if (shouldRedirect === true) {
            window.location.replace(`/patient/${patient_id}`);
          }
        } else {
          const data = await response.json();
          console.log(data);
          setResponseMessage(
            "An error had occured adding a Surgery to this patient. Please contact the Admin for further assistance."
          );
        }

        return;
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding a Surgery to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding a Surgery to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  const handleNewBilling = async (data, shouldRedirect, patient_id) => {
    const payload = {
      patient_info_id: data.newPatientInformation.patient_info_id,
      type: billingType,
    };

    try {
      const response = await createBilling(payload);
      if (response.ok) {
        const data = await response.json();
        // console.log(data);

        switch (billingType) {
          case "Insurance":
            try {
              const response = await createInsurance({
                billing_id: data.newBilling.id,
                provider: insuranceProvider,
                group_policy_number: policyNumber,
                name_on_insurance: nameOnInsurance,
              });
              if (response.ok) {
                // const data = await response.json();
                // console.log(data);

                if (shouldRedirect === true) {
                  window.location.replace(`/patient/${patient_id}`);
                }

                return;
              } else {
                const data = await response.json();
                console.log(data);
                setResponseMessage(
                  "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
                );
              }
            } catch (error) {
              console.error(error);
              setResponseMessage(
                "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
              );
            }
            break;
          default:
            return;
        }
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding Billing to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding Billing to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  const handleNewInsurance = async () => {
    try {
      const response = await createInsurance({
        // billing_id: data.newBilling.id,
        billing_id: 127,
        provider: insuranceProvider,
        group_policy_number: policyNumber,
        name_on_insurance: nameOnInsurance,
      });
      if (response.ok) {
        // const data = await response.json();
        // console.log(data);
        return;
      } else {
        const data = await response.json();
        console.log(data);
        setResponseMessage(
          "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
        );
      }
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error had occured adding Insurance to this patient. Please contact the Admin for further assistance."
      );
    }
  };

  // add insurance information to the insurances state
  const handleAddInsurance = () => {
    // Check if the insurance information is filled
    if (insuranceProvider && policyNumber && nameOnInsurance) {
      const newInsurance = {
        insurance_provider: insuranceProvider,
        policy_number: policyNumber,
        name_on_insurance: nameOnInsurance,
      };
      setInsurances([...insurances, newInsurance]);
      // Clear insurance fields
      setInsuranceProvider("");
      setPolicyNumber("");
      setNameOnInsurance("");
    } else {
      alert("Please fill all insurance fields before adding.");
    }
  };

  // Fetch doctors for dropdown
  useEffect(() => {
    getDoctors()
      .then((res) => res.json())
      .then((data) => {
        setDoctors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column patient-form-media">
        <BackgroundImage />
        <div>
          <form className="new-patient-form" onSubmit={handleSubmit}>
            <h1>New Patient Information</h1>
            <div className="border-bottom"></div>
            <div className="patient-form-row">
              <div className="patient-form-group">
                <label htmlFor="first-name">
                  Name<span className="asterick"> *</span>
                </label>
                <input
                  type="text"
                  id="first-name"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label className="bottom-label" htmlFor="first-name">
                  First Name
                </label>
              </div>
              <div className="patient-form-group">
                <label htmlFor="last-name">&nbsp;</label>
                <input
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <label className="bottom-label" htmlFor="last-name">
                  Last Name
                </label>
              </div>
              <div className="patient-form-group">
                <label htmlFor="dob">
                  Date of Birth<span className="asterick"> *</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>
            </div>

            {/* Doctors */}
            <div className="patient-form-row">
              <div className="patient-form-group">
                <label htmlFor="surgeon">
                  Surgeon<span className="asterick"> *</span>
                </label>
                <select
                  value={surgeon}
                  onChange={(e) => {
                    e.target.value === "Other"
                      ? setManualInput(true)
                      : setManualInput(false);

                    setSurgeon(e.target.value);
                  }}
                  id="surgeon"
                  required
                  className="patient-dr"
                >
                  <option value="">Select Surgeon</option>
                  {/* Doctors from the DB */}
                  {doctors.length > 0 ? (
                    doctors.map((doctor) => (
                      <option
                        key={doctor.id}
                        value={`${doctor.first_name} ${doctor.last_name}`}
                      >
                        {doctor.first_name} {doctor.last_name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                  <option value="Other">Other</option>
                </select>
                {/* Manual Input */}
                {manualInput === true ? (
                  <input
                    type="tel"
                    id="patient-tel"
                    style={{ marginTop: "10px" }}
                    required
                    value={surgeonInput}
                    placeholder="Enter Surgeon Name"
                    onChange={(e) => {
                      setSurgeonInput(e.target.value);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="patient-form-group">
                <label htmlFor="patient-tel">
                  Phone Number<span className="asterick"> *</span>
                </label>
                <input
                  type="tel"
                  id="patient-tel"
                  required
                  value={phoneNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setPhoneNumber(phone);
                  }}
                />
              </div>
              <div className="patient-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="patient-form-row">
              <div className="patient-form-group language">
                <label htmlFor="preferred-languages">Preferred Languages</label>
                <input
                  type="text"
                  id="preferred-languages"
                  placeholder="Preferred Languages"
                  value={preferredLanguage}
                  onChange={(e) => setPreferredLanguage(e.target.value)}
                />
              </div>
            </div>

            <hr
              style={{ marginTop: "30px", border: ".5px solid #83829266" }}
            ></hr>

            <div className="patient-form-row">
              <button
                type="button"
                className="add-patient secondary"
                onClick={() => {
                  createNewSurgery
                    ? setcreateSurgery(false)
                    : setcreateSurgery(true);
                }}
              >
                {createNewSurgery ? "Remove Surgery" : "Add Surgery"}
              </button>
            </div>
            <br></br>

            {/* Surgery */}
            {createNewSurgery ? (
              <div className="patient-form-row">
                <div className="patient-form-group">
                  <label htmlFor="date-of-service">
                    Date of Service <span className="asterick"> *</span>
                  </label>
                  <input
                    type="date"
                    id="date-of-service"
                    className="date-of-service"
                    required
                    value={dos}
                    onChange={(e) => setDos(e.target.value)}
                  />
                </div>
                <div className="patient-form-group">
                  <label htmlFor="time-of-service">
                    Time of Service <span className="asterick"> *</span>
                  </label>
                  <input
                    type="time"
                    id="time-of-service"
                    className="time-of-service"
                    required
                    value={tos}
                    onChange={(e) => setTos(e.target.value)}
                  />
                </div>
                <div className="patient-form-group">
                  <label htmlFor="surgery-type">
                    Surgery Type <span className="asterick"> *</span>
                  </label>
                  <input
                    type="text"
                    id="surgery-type"
                    value={surgeryType}
                    required
                    onChange={(e) => setSurgeryType(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <hr
              style={{ marginTop: "10px", border: ".5px solid #83829266" }}
            ></hr>

            {/* Billing Information */}
            <div className="patient-form-row">
              <button
                type="button"
                className="add-patient secondary"
                onClick={() => {
                  createNewBilling
                    ? setcreateBilling(false)
                    : setcreateBilling(true);
                }}
              >
                {createNewBilling ? "Remove Billing" : "Add Billing"}
              </button>
            </div>

            <br></br>

            {createNewBilling ? (
              <>
                <div className="patient-form-row">
                  <div className="patient-form-group billing">
                    <label htmlFor="billing-type">Billing Type</label>
                    <select
                      id="billing-type"
                      className="billing-type"
                      value={billingType}
                      onChange={(e) => setBillingType(e.target.value)}
                    >
                      <option>Insurance</option>
                      <option>Worker's Comp</option>
                      <option>Personal Injury</option>
                      <option>Out of Pocket</option>
                    </select>
                  </div>
                </div>

                {billingType === "Insurance" ? (
                  <>
                    {" "}
                    <div className="form-row insurance-section">
                      <div className="insurance-details">
                        <div className="patient-form-group">
                          <label htmlFor="insurance-provider">
                            Provider <span className="asterick"> *</span>
                          </label>
                          <input
                            type="text"
                            id="insurance-provider"
                            value={insuranceProvider}
                            required
                            onChange={(e) =>
                              setInsuranceProvider(e.target.value)
                            }
                          />
                          {/* provider details below */}
                          {insurances.map((insurance, index) => (
                            <div key={index} className="insurance-item">
                              <p>{insurance.insurance_provider}</p>
                            </div>
                          ))}
                        </div>
                        <div className="patient-form-group">
                          <label htmlFor="policy-number">
                            Group Policy Number{" "}
                            <span className="asterick"> *</span>
                          </label>
                          <input
                            type="text"
                            id="policy-number"
                            value={policyNumber}
                            required
                            onChange={(e) => setPolicyNumber(e.target.value)}
                          />
                          {/*  policy number details below */}
                          {insurances.map((insurance, index) => (
                            <div key={index} className="insurance-item">
                              <p>{insurance.policy_number}</p>
                            </div>
                          ))}
                        </div>
                        <div className="patient-form-group">
                          <label htmlFor="name-on-insurance">
                            Name on Insurance{" "}
                            <span className="asterick"> *</span>
                          </label>
                          <input
                            type="text"
                            id="name-on-insurance"
                            required
                            value={nameOnInsurance}
                            onChange={(e) => setNameOnInsurance(e.target.value)}
                          />
                          {/*  name on insurance details below */}
                          {insurances.map((insurance, index) => (
                            <div key={index} className="insurance-item">
                              <p>{insurance.name_on_insurance}</p>
                            </div>
                          ))}
                        </div>
                        {/* <div className="patient-form-group-btn center">
                          <label>&nbsp;</label>
                          <button
                            type="button"
                            className="insurance-add-btn"
                            onClick={handleAddInsurance}
                          >
                            Add
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <hr
              style={{ marginTop: "10px", border: ".5px solid #83829266" }}
            ></hr>

            <div className="patient-form-row">
              <button
                type="submit"
                className="add-patient"
                disabled={isLoading}
              >
                {isLoading ? "Adding Patient..." : "Add Patient"}
              </button>
            </div>
            {/*response message */}
            {responseMessage && (
              <p className="response-message">{responseMessage}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPatientForm;
